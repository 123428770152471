import merge from "lodash/merge";
import { combineReducers } from "redux";
import * as CONSTANTS from "../constants/constants";

const loginState = {
  status: CONSTANTS.NOT_LOGGED,
};

const loginReducer = (state = { loginState }, action) => {
  if (
    action.type !== CONSTANTS.LOGIN_SUCCESS &&
    action.type !== CONSTANTS.LOGIN_ERROR &&
    action.type !== CONSTANTS.LOGIN_LOADING &&
    action.type !== CONSTANTS.LOGOUT_SUCCESS &&
    action.type !== CONSTANTS.CHECK_TOKEN_SUCCESS &&
    action.type !== CONSTANTS.CHECK_TOKEN_ERROR
  )
    return state;

  if (action.type === CONSTANTS.LOGIN_LOADING) {
    return merge({}, state, { status: CONSTANTS.LOGIN_LOADING });
  }

  if (action.type === CONSTANTS.LOGIN_ERROR) {
    return merge({}, state, { status: CONSTANTS.NOT_LOGGED });
  }

  if (action.type === CONSTANTS.CHECK_TOKEN_SUCCESS) {
    return merge({}, state, { status: CONSTANTS.LOGGED });
  }

  if (action.type === CONSTANTS.CHECK_TOKEN_ERROR) {
    return merge({}, state, { status: CONSTANTS.NOT_LOGGED });
  }

  if (action.userData && action.userData.response) {
    let token = action.userData.response.access_token;
    if (token !== undefined)
      return merge(
        {},
        state,
        { logged: action.userData.response },
        { status: CONSTANTS.LOGGED }
      );
  }
  return merge({}, state, { status: CONSTANTS.NOT_LOGGED });
};

const changePasswordReducer = (state = { loginState }, action) => {
  if (
    action.type !== CONSTANTS.CHANGE_PASSWORD_SUCCESS &&
    action.type !== CONSTANTS.CHANGE_PASSWORD_LOADING &&
    action.type !== CONSTANTS.CHANGE_PASSWORD_ERROR &&
    action.type !== CONSTANTS.CHANGE_PASSWORD_LOGGED_SUCCESS &&
    action.type !== CONSTANTS.CHANGE_PASSWORD_LOGGED_LOADING &&
    action.type !== CONSTANTS.CHANGE_PASSWORD_LOGGED_ERROR
  )
    return state;

  if (action.type === CONSTANTS.CHANGE_PASSWORD_SUCCESS) {
    return merge({}, state, { status: CONSTANTS.CHANGE_PASSWORD_SUCCESS });
  }

  if (action.type === CONSTANTS.CHANGE_PASSWORD_LOADING) {
    return merge({}, state, { status: CONSTANTS.CHANGE_PASSWORD_LOADING });
  }

  if (action.type === CONSTANTS.CHANGE_PASSWORD_ERROR) {
    return merge({}, state, { status: CONSTANTS.CHANGE_PASSWORD_ERROR });
  }

  if (action.type === CONSTANTS.CHANGE_PASSWORD_LOGGED_SUCCESS) {
    return merge({}, state, {
      status: CONSTANTS.CHANGE_PASSWORD_LOGGED_SUCCESS,
    });
  }

  if (action.type === CONSTANTS.CHANGE_PASSWORD_LOGGED_LOADING) {
    return merge({}, state, {
      status: CONSTANTS.CHANGE_PASSWORD_LOGGED_LOADING,
    });
  }

  if (action.type === CONSTANTS.CHANGE_PASSWORD_LOGGED_ERROR) {
    return merge({}, state, { status: CONSTANTS.CHANGE_PASSWORD_LOGGED_ERROR });
  }

  let response = action.userData.response;
  if (response) {
    let newState = response;
    return merge([], state, newState);
  }
  return merge({}, state, action.payload);
};

let mapping = {};

const generateMapping = (reducerName, mapa) => {
  Object.keys(mapa).forEach((item) => {
    mapping[[CONSTANTS[reducerName + "_" + item + "_LOADING"]]] = {
      result: mapa[item],
      fetchStatus: mapa[item] + "Status",
    };
    mapping[[CONSTANTS[reducerName + "_" + item + "_SUCCESS"]]] = {
      result: mapa[item],
      fetchStatus: mapa[item] + "Status",
    };
    mapping[[CONSTANTS[reducerName + "_" + item + "_ERROR"]]] = {
      result: mapa[item],
      fetchStatus: mapa[item] + "Status",
    };
  });
};

/**************************************/
/**    AGREGAR COMPONENTES ACA       **/
/**************************************/

let mappingParameters = {};

const NEW_FORM_PREFIX = "NEW_FORM";

mappingParameters[NEW_FORM_PREFIX] = {
  EMITIR: "emision",
  PROVINCIAS: "provincias",
  LOCALIDADES: "localidades",
  CODIGO_POSTAL: "codigoPostal",
  GETEMISIONES: "emisiones",
  PRODUCTOS: "productos",
  DOWNLOADPDF: "pdfFile",
  CREATE_USER: "user",
  USER_EXIST: "userExist",
  FIND_USERS: "users",
  UPDATE_USER: "userUpdated",
  DOWNLOAD_CSV: "csvFile",
  ENTIDADES: "entidades",
  RAZAS: "razas",
  CHECK_EMAIL_VALID: "emailValid",
};

/**************************************/

generateMapping(NEW_FORM_PREFIX, mappingParameters[NEW_FORM_PREFIX]);

const searchReducer = (state = { loginState }, action) => {
  if (
    action.type !== CONSTANTS.NEW_FORM_CODIGO_POSTAL_LOADING &&
    action.type !== CONSTANTS.NEW_FORM_CODIGO_POSTAL_SUCCESS &&
    action.type !== CONSTANTS.NEW_FORM_CODIGO_POSTAL_ERROR &&
    action.type !== CONSTANTS.NEW_FORM_CODIGO_POSTAL_RESET
  )
    return state;

  if (action.type === CONSTANTS.NEW_FORM_CODIGO_POSTAL_SUCCESS) {
    let result = action.userData;
    let provincias = {};
    state.localidades = [];
    state.provincias = [];
    return merge({}, state, {
      codigoPostalStatus: CONSTANTS.NEW_FORM_CODIGO_POSTAL_SUCCESS,
      provincias: result
        .filter((prov) => {
          var exists = !provincias[prov.id];
          provincias[prov.id] = true;
          return exists;
        })
        .map((prov) => ({ id: prov.id, descripcion: prov.descripcion })),
      localidades: result.map((loc) => ({
          id: loc.localidad,
          descripcion: loc.localidad_descripcion,
          provincia: loc.id,
        })),
    });
  }

  if (action.type === CONSTANTS.NEW_FORM_CODIGO_POSTAL_ERROR) {
    return merge({}, state, {
      codigoPostalStatus: CONSTANTS.NEW_FORM_CODIGO_POSTAL_ERROR,
    });
  }

  if (action.type === CONSTANTS.NEW_FORM_CODIGO_POSTAL_RESET) {
    return merge({}, state, {
      codigoPostalStatus: CONSTANTS.NEW_FORM_CODIGO_POSTAL_RESET,
     });
  }

  if (action.type === CONSTANTS.NEW_FORM_CODIGO_POSTAL_LOADING) {
    return merge({}, state, {
      codigoPostalStatus: CONSTANTS.NEW_FORM_CODIGO_POSTAL_LOADING,
    });
  }
};

const homeReducer = (state = { loginState }, action) => {
  if (action.type.startsWith(NEW_FORM_PREFIX) === false) return state;

  if (action.type.endsWith("LOADING") || action.type.endsWith("ERROR")) {
    let newState = { [mapping[action.type].fetchStatus]: action.type };
    return merge([], state, newState);
  }

  if (action.valid && action.type.endsWith("SUCCESS")) {
    let newState = state;
    newState[mapping[action.type].result] = action.valid;
    newState[mapping[action.type].fetchStatus] = action.type;
    return merge({}, state, newState);
  }

  let response = action.userData;

  if (response && action.type.endsWith("SUCCESS")) {
    let newState = state;
    newState[mapping[action.type].result] = response;
    newState[mapping[action.type].fetchStatus] = action.type;
    return merge({}, state, newState);
  }
  return merge({}, state, action.payload);
};

const forgotPasswordReducer = (state = { loginState }, action) => {
  if (
    action.type !== CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_SUCCESS &&
    action.type !== CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_LOADING &&
    action.type !== CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_ERROR
  )
    return state;

  if (action.type === CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_SUCCESS) {
    return merge({}, state, {
      forgotPasswordStatus: CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_SUCCESS,
    });
  }

  if (action.type === CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_LOADING) {
    return merge({}, state, {
      forgotPasswordStatus: CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_LOADING,
    });
  }

  if (action.type === CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_ERROR) {
    return merge({}, state, {
      forgotPasswordStatus: CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_ERROR,
    });
  }

  let response = action.userData.response;
  if (response) {
    let newState = response;
    return merge([], state, newState);
  }
  return merge({}, state, action.payload);
};

const rootReducer = combineReducers({
  loginReducer,
  searchReducer,
  homeReducer,
  changePasswordReducer,
  forgotPasswordReducer,
});

export default rootReducer;
