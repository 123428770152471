import React from "react";

function SpinnerWithDimmerCmp(props) {

    return (
        <div className={"ui dimmer " + (props.active ? 'active' : '' )}>
            <div className="spinner-message-emision">
                <div className="ui medium text loader">{props.title}</div>              
            </div>
        </div>
    )
}

export default SpinnerWithDimmerCmp;