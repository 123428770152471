import React, { useState, useEffect/*, useCallback*/ } from "react";
import { Form, Input } from "semantic-ui-react";
import _ from "lodash"
function SelectizeCmp(props) {

    /*const [openModal, setOpenModal] = useState(false);
    const [prevState, setPrevState] = useState(false);
    const [currentState, setCurrentState] = useState(true);
    const [items, setItems] = useState([]);

    function cerrarCoberturas(){
        setOpenModal(false)
    }

    const parseCoberturas = useCallback(() =>{
        let json = JSON.parse(props.productoPlan.information).descripcion
        generateItems(json)
    },[props.productoPlan.information])

    useEffect(() => {
        setCurrentState(props.show)
        if(props.show && currentState !== prevState && props.show === true){
            setOpenModal(true)
            
            setPrevState(props.show)
            
        }
    }, [props.show,currentState, prevState, parseCoberturas])
    */
    /*const parseCoberturas = () =>{
            let json = JSON.parse(props.productoPlan.information).descripcion
            generateItems(json)
    }*/

    const [openSelect, setOpenSelect] = useState(false); 
    const [localidadesFiltradas, setLocalidadesFiltradas] = useState(); 
    const [localidadesSelected, setLocalidadesSelected] = useState(false); 
  
    const filterLocalidad = (value) =>{
      if(value.length<3)
        return;
      let encontradas = _.filter(props.localidades, function(unaLocalidad){
        return unaLocalidad.text.toUpperCase().startsWith(value.toUpperCase())
      })
      setLocalidadesFiltradas(generateSelectizeRows(encontradas))
    }
  
    const selectItem = (elem) =>{
      setLocalidadesSelected(true)
      setOpenSelect(false)
      props.onChange({target:{name :props.name, value: elem.text}}, props.validateLocalidad)
      setLostFocusLocalidad(false)
    }

    const changeLocalidadSelected = () =>{
        //setLocalidadesSelected(false)
        setLostFocusLocalidad(false)
      }
    
      const generateSelectizeRows = (encontradas) =>{
        let arraySelectize = []
        encontradas.forEach(element=>{
          arraySelectize.push(
                              <div key={element.id} className="field-selectize" onClick={()=>selectItem(element)}>
                                  {element.text}
                              </div>
                             )
        })
        return arraySelectize; 
      }
    
      const [lostFocusLocalidad, setLostFocusLocalidad] = useState(false);
    
      const closeList = () => {
        setOpenSelect(false)
        setLostFocusLocalidad(true)
      }
      
      const onLostFocusLocalidad = () => {
        setTimeout(closeList,300)
      }

      
    useEffect(() => {
        if(lostFocusLocalidad && !localidadesSelected){
          setLocalidadesSelected(true)
          props.validateLocalidad(props.value, props.name)
        }
    },[lostFocusLocalidad, localidadesSelected, props])

    const cambiarSelected = () => {
      
      setLocalidadesSelected(false)
    }

    const onChange = (e) =>{
      cambiarSelected();
      props.onChange(e, filterLocalidad);
    }

    return (
        <div className={"field-selectize-container "} >
            <Form.Field
            control={Input}
            className="input-selectize"
            autoComplete="off"
            value={props.value}
            name={props.name}
            label={props.label}
            placeholder={props.label}
            onChange={onChange}
            onClick={changeLocalidadSelected}
            error={props.error}
            onBlur={()=>onLostFocusLocalidad(lostFocusLocalidad)}
            maxLength="10"
            disabled={props.disabled}
            icon='search' 
            ></Form.Field>
            {lostFocusLocalidad}
            <div className={"row-container-selectize " + (props.value && props.value.length > 2 && 
                            !openSelect && !lostFocusLocalidad ? "show" : "not-show ") + (props.error === undefined ? '' : ' error ')
                            }>
            {localidadesFiltradas}
            </div>
        </div>  
    );
}
export default SelectizeCmp