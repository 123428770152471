
import CreateUserCmp from '../components/CreateUserCmp.js';

const { connect } = require('react-redux');
const { logout, checkToken } = require('../actions/loginAction')

const { usernameExist, createUser, getEntidades, checkEmailValid} = require('../actions/homeAction')

const mapStateToProps = (state) => {
    return{
      status: state.loginReducer.status,
      userStatus: state.homeReducer.userStatus,
      user: state.homeReducer.user,
      userExistStatus: state.homeReducer.userExistStatus,
      userExist: state.homeReducer.userExist,
      entidades: state.homeReducer.entidades,
      entidadesStatus: state.homeReducer.entidadesStatus,
      checkEmailValid: state.homeReducer.emailValid
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  usernameExist: (payload) => {
    dispatch(usernameExist(payload));
  },
  createUser: (payload) => {
    dispatch(createUser(payload));
  },
  getEntidades: () => {
    dispatch(getEntidades());
  },
  logout: () => {
    dispatch(logout());
  },
  checkEmail: payload => {
    dispatch(checkEmailValid(payload));
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserCmp);
