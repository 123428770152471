/* eslint-disable no-constant-condition */
import { takeEvery, /*take,*/ put, call, /*fork,*/ /*select,*/ all  } from 'redux-saga/effects'
import { loginServices } from '../services'
//import Cookies from 'js-cookie';
import {API_LOGIN_URL, API_URL} from '../config/config'

import * as CONSTANTS from '../constants/constants'
//import { searchCodigoPostal } from '../actions/homeAction';

export function* login(payload) {
  try {
    //console.log("url: ", API_LOGIN_URL)
    const result = yield call(loginServices.loginPOST,`${API_LOGIN_URL}/oauth/token`, payload);

    localStorage.setItem('account', JSON.stringify(result.response).toString())
    yield put({type: CONSTANTS.LOGIN_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.LOGIN_ERROR, message: e.message});
  }
}

export function* logout() {
  try {
    const result = yield call(loginServices.logoutPOST,`${API_LOGIN_URL}/oauth/revoke_token`);
    //console.log(result)
    yield put({type: CONSTANTS.LOGOUT_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.LOGOUT_ERROR, message: e.message});
  }
}

export function* checkToken() {
  try {
    const result = yield call(loginServices.checkToken,`${API_LOGIN_URL}/oauth/check_token`);
    //console.log(result)
    yield put({type: CONSTANTS.CHECK_TOKEN_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.CHECK_TOKEN_ERROR, message: e.message});
  }
}

export function* sendMailChangePassword(payload) {
  try {
    //console.log(`${API_LOGIN_URL}/users/change-password/email`, payload)
    const result = yield call(loginServices.sendMailChangePassword,`${API_LOGIN_URL}/users/change-password/email`, payload.payload);
    yield put({type: CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_ERROR, message: e.message});
  }
}

export function* changePassword(payload) {
  try {
    //console.log(API_LOGIN_URL + "/users/change-password/", payload.payload.pathVariable.token)
    const result = yield call(loginServices.changePassword,`${API_LOGIN_URL}/users/change-password/${payload.payload.pathVariable.token}`, payload.payload.body);
    yield put({type: CONSTANTS.CHANGE_PASSWORD_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.CHANGE_PASSWORD_ERROR, message: e.message});
  }
}

export function* changePasswordLogged(payload) {
  try {
    //console.log(payload.payload.body)
    const result = yield call(loginServices.postCall,`${API_URL}/api/users/change-password-logged/${payload.payload.pathVariable}`, payload);
    yield put({type: CONSTANTS.CHANGE_PASSWORD_LOGGED_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.CHANGE_PASSWORD_LOGGED_ERROR, message: e.message});
  }
}

/* post secure */
export function* emitir(payload) {
  try {
    console.log("emision: ",payload.payload)
    const result = yield call(loginServices.postCall,`${API_URL}/api/customer-product/emitir`, payload);
    yield put({type: CONSTANTS.NEW_FORM_EMITIR_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_EMITIR_ERROR, message: e.message});
  }
}

/* post secure */
export function* createUser(payload) {
  try {
    const result = yield call(loginServices.postCall,`${API_URL}/api/users/admin`, payload);
    yield put({type: CONSTANTS.NEW_FORM_CREATE_USER_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_CREATE_USER_ERROR, message: e.message});
  }
}

/* post secure */
export function* usernameExist(payload) {
  try {
    const result = yield call(loginServices.getCall,`${API_URL}/api/users/admin/${payload.payload.pathVariable}`, payload);
    yield put({type: CONSTANTS.NEW_FORM_USER_EXIST_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_USER_EXIST_ERROR, message: e.message});
  }
}

/* get secure */
export function* searchCodigoPostal(payload) {
  try {
    const result = yield call(loginServices.getCall,`${API_URL}/api/geo/provincias/cp/${payload.payload}`);
    yield put({type: CONSTANTS.NEW_FORM_CODIGO_POSTAL_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_CODIGO_POSTAL_ERROR, message: e.message});
  }
}

/* get secure */
export function* getProductos() {
  try {
    //console.log("get productos")
    const result = yield call(loginServices.getCall,`${API_URL}/api/product `);
    yield put({type: CONSTANTS.NEW_FORM_PRODUCTOS_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_PRODUCTOS_ERROR, message: e.message});
  }
}
export function* getEmisiones(payload) {
  try {
    //console.log("emision: ", payload.payload)
    const result = yield call(loginServices.postCall,`${API_URL}/api/customer-product/users/${payload.payload.pathVariable.username}`, payload.payload);
    yield put({type: CONSTANTS.NEW_FORM_GETEMISIONES_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_GETEMISIONES_ERROR, message: e.message});
  }
}

export function* descargaPDF(payload) {
  try {
    //console.log("descarga pdf: ", payload.payload.pathVariable.id)
    const result = yield call(loginServices.getFile,`${API_URL}/api/documentos/pdf/cotizaciones/${payload.payload.pathVariable.id}`);
    yield put({type: CONSTANTS.NEW_FORM_DOWNLOADPDF_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_DOWNLOADPDF_ERROR, message: e.message});
  }
}

export function* findUsers(payload) {
  try {
    //console.log("findUsers",payload)
    const result = yield call(loginServices.getCall,`${API_URL}/api/users/admin?search=${payload.payload.query.search}&entityId=${payload.payload.query.entityId}`);
    yield put({type: CONSTANTS.NEW_FORM_FIND_USERS_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_FIND_USERS_ERROR, message: e.message});
  }
}

export function* updateUser(payload) {
  try {
    //console.log("updateUser",payload)
    const result = yield call(loginServices.putCall,`${API_URL}/api/users/admin/enabled/${payload.payload.pathVariable.id}`, payload.payload.body);
    yield put({type: CONSTANTS.NEW_FORM_UPDATE_USER_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_UPDATE_USER_ERROR, message: e.message});
  }
}

export function* downloadCSV(payload) {
  try {
    console.log("descarga csv: ", payload.payload.fecha, " Entidad: ", payload.payload.entityId)
    const result = yield call(loginServices.getFile,`${API_URL}/api/documentos/admin/csv/online/emisiones/${payload.payload.fecha}/entityId/${payload.payload.entityId}`);
    yield put({type: CONSTANTS.NEW_FORM_DOWNLOAD_CSV_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_DOWNLOAD_CSV_ERROR, message: e.message});
  }
}

export function* getEntidades() {
  try {
    //console.log("get entidades")
    const result = yield call(loginServices.getCall,`${API_URL}/api/entidades`);
    yield put({type: CONSTANTS.NEW_FORM_ENTIDADES_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_ENTIDADES_ERROR, message: e.message});
  }
}

export function* getRazas({payload}){
  try {
    //console.log("get razas", payload)
    const result = yield call(loginServices.getCall,`${API_URL}/api/razas/${payload}`);
    yield put({type: CONSTANTS.NEW_FORM_RAZAS_SUCCESS, userData: result});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_RAZAS_ERROR, message: e.message});
  }
}

export function* checkEmail({payload}) {
  try {
    const result = yield call(loginServices.checkEmail,`${API_LOGIN_URL}/users/check-email-valid`, payload);
    yield put({type: CONSTANTS.NEW_FORM_CHECK_EMAIL_VALID_SUCCESS, valid: result.response.valid});
  } catch (e) {
    yield put({type: CONSTANTS.NEW_FORM_CHECK_EMAIL_VALID_ERROR});
  }
}

export default function* root() {
  yield takeEvery(CONSTANTS.NEW_FORM_EMITIR_LOADING, emitir);
  yield takeEvery(CONSTANTS.LOGIN_LOADING, login);
  yield takeEvery(CONSTANTS.LOGOUT_LOADING, logout);
  yield takeEvery(CONSTANTS.LOGOUT_ERROR, checkToken)
  yield takeEvery(CONSTANTS.CHECK_TOKEN_LOADING, checkToken)
  yield takeEvery(CONSTANTS.POST_EXAMPLE_ERROR, checkToken)
  yield takeEvery(CONSTANTS.CHANGE_PASSWORD_LOADING, changePassword)
  yield takeEvery(CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_LOADING, sendMailChangePassword)
  yield takeEvery(CONSTANTS.NEW_FORM_CHECK_EMAIL_VALID_LOADING, checkEmail)
  yield takeEvery(CONSTANTS.NEW_FORM_CODIGO_POSTAL_LOADING, searchCodigoPostal)
  yield takeEvery(CONSTANTS.NEW_FORM_GETEMISIONES_LOADING, getEmisiones)
  yield takeEvery(CONSTANTS.NEW_FORM_PRODUCTOS_LOADING, getProductos)
  yield takeEvery(CONSTANTS.NEW_FORM_DOWNLOADPDF_LOADING, descargaPDF)
  yield takeEvery(CONSTANTS.NEW_FORM_USER_EXIST_LOADING, usernameExist);
  yield takeEvery(CONSTANTS.NEW_FORM_CREATE_USER_LOADING, createUser);
  yield takeEvery(CONSTANTS.CHANGE_PASSWORD_LOGGED_LOADING, changePasswordLogged);
  yield takeEvery(CONSTANTS.NEW_FORM_FIND_USERS_LOADING, findUsers);
  yield takeEvery(CONSTANTS.NEW_FORM_UPDATE_USER_LOADING, updateUser);
  yield takeEvery(CONSTANTS.NEW_FORM_DOWNLOAD_CSV_LOADING, downloadCSV);
  yield takeEvery(CONSTANTS.NEW_FORM_ENTIDADES_LOADING, getEntidades);
  yield takeEvery(CONSTANTS.NEW_FORM_RAZAS_LOADING, getRazas);

  yield all([])
}

