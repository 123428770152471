
import NewFormCmp from '../components/NewFormCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/loginAction')
const { emitir, searchCodigoPostal, getProductos, getRazas, resetGeo } = require('../actions/homeAction')

const mapStateToProps = (state) => {
    return{
      provincias: state.searchReducer.provincias,
      productos: state.homeReducer.productos,
      productosStatus: state.homeReducer.productosStatus,
      localidades: state.searchReducer.localidades,
      codigoPostalStatus: state.searchReducer.codigoPostalStatus,
      emision: state.homeReducer.emision,
      emisionStatus: state.homeReducer.emisionStatus,
      status: state.loginReducer.status,
      razas: state.homeReducer.razas,
      razasStatus: state.homeReducer.razasStatus
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  searchCodigoPostal: (payload) => {
    dispatch(searchCodigoPostal(payload));
  },
  resetGeo: () => {
    dispatch(resetGeo())
  },
  emitir: (payload) => {
    dispatch(emitir(payload));
  },
  getProductos: () => {
    dispatch(getProductos())
  },
  getRazas: (payload) => {
    dispatch(getRazas(payload))
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(NewFormCmp);
