export const HOME = '/home'

export const LOGIN_ROOT = '/'      
export const LOGIN = '/login'
export const OLVIDE_MI_CLAVE = '/olvide-mi-clave';
export const CHANGE_PASSWORD = '/cambiar-password';
export const LIST = '/list'
export const CREATE ='/new-form'
export const CREATE_USER = '/create-user'
export const LIST_ADMIN = '/list-users-admin'
export const SUCCESS = '/success'
export const CAMBIAR_PASSWORD_LOGGED = '/cambiar-password-logged'
export const DOWNLOAD_CSV = '/download-csv'