
import MenuCmp from '../components/MenuCmp.js';

const { connect } = require('react-redux');

const { logout  } = require('../actions/loginAction')


const mapStateToProps = (state) => {
    return{
      status: state.loginReducer.status
    }
};

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(logout());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(MenuCmp);
