import React, { useState } from 'react';
import { func, string, array, bool } from 'prop-types';
import { Form, Radio } from "semantic-ui-react";

const OptionSelectComponent = ({
  options,
  name,
  label,
  callbackPre,
  callbackPost,
  onChange,
  value,
  error,
  required
}) => {

  let titleLabel = label;
  let callPre = callbackPre ? callbackPre : undefined;
  let callPost = callbackPost ? callbackPost : undefined;
  const [selectItem, setSelectItem] = useState(value);
  
  const handleChangeItems = value => {
    setSelectItem(value);
    onChange({target: { name, value }}, callPre, callPost);
  }

  let optionsSelect = options.map((item, index) => (
    <Radio 
      key={`${item.value}-${index}`} 
      className="checked-radius" 
      label={item.text} 
      name={name} 
      checked={selectItem === item.value} 
      onClick={() => handleChangeItems(item.value)} 
      required={required} />
  ));
  
  return (
    <Form.Field error={error !== undefined}>
      <label>{titleLabel}</label>
      {optionsSelect}
      { error ?
        <div className="ui pointing above prompt label error-custom" role="alert" aria-atomic="true">{error}</div>
        : null }
    </Form.Field>
  )
}

OptionSelectComponent.propTypes = {
  options: array.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  callbackPre: func,
  callbackPost: func,
  onChange: func.isRequired,
  error: string,
  value: string,
  required: bool
};

export default OptionSelectComponent;