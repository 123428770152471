/***************LOGIN **************/
/***********************************/

export const LOGIN_LOADING = 'LOGIN_LOADING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const LOGOUT_LOADING = 'LOGOUT_LOADING'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const CHECK_TOKEN_LOADING = 'CHECK_TOKEN_LOADING'
export const CHECK_TOKEN_SUCCESS = 'CHECK_TOKEN_SUCCESS'
export const CHECK_TOKEN_ERROR = 'CHECK_TOKEN_ERROR'

/***********************************/

export const POST_EXAMPLE_LOADING = 'POST_EXAMPLE_LOADING'
export const POST_EXAMPLE_SUCCESS = 'POST_EXAMPLE_SUCCESS'
export const POST_EXAMPLE_ERROR = 'POST_EXAMPLE_ERROR'

export const NOT_LOGGED = 'NOT_LOGGED'
export const LOGGED = 'LOGGED'

export const INVALID = 'INVALID'
export const VALID = 'VALID'

export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR'

export const CHANGE_PASSWORD_LOGGED_LOADING = 'CHANGE_PASSWORD_LOGGED_LOADING'
export const CHANGE_PASSWORD_LOGGED_SUCCESS = 'CHANGE_PASSWORD_LOGGED_SUCCESS'
export const CHANGE_PASSWORD_LOGGED_ERROR = 'CHANGE_PASSWORD_LOGGED_ERROR'

export const SENT_MAIL_CHANGE_PASSWORD_LOADING = 'SENT_MAIL_CHANGE_PASSWORD_LOADING'
export const SENT_MAIL_CHANGE_PASSWORD_SUCCESS = 'SENT_MAIL_CHANGE_PASSWORD_SUCCESS'
export const SENT_MAIL_CHANGE_PASSWORD_ERROR = 'SENT_MAIL_CHANGE_PASSWORD_ERROR'

/***********************************/

export const NEW_FORM_EMITIR_LOADING = 'NEW_FORM_EMITIR_LOADING'
export const NEW_FORM_EMITIR_SUCCESS = 'NEW_FORM_EMITIR_SUCCESS'
export const NEW_FORM_EMITIR_ERROR = 'NEW_FORM_EMITIR_ERROR'

export const NEW_FORM_CREATE_USER_LOADING = 'NEW_FORM_CREATE_USER_LOADING'
export const NEW_FORM_CREATE_USER_SUCCESS = 'NEW_FORM_CREATE_USER_SUCCESS'
export const NEW_FORM_CREATE_USER_ERROR = 'NEW_FORM_CREATE_USER_ERROR'

export const NEW_FORM_USER_EXIST_LOADING = 'NEW_FORM_USER_EXIST_LOADING'
export const NEW_FORM_USER_EXIST_SUCCESS = 'NEW_FORM_USER_EXIST_SUCCESS'
export const NEW_FORM_USER_EXIST_ERROR = 'NEW_FORM_USER_EXIST_ERROR'

export const NEW_FORM_PROVINCIAS_LOADING = 'NEW_FORM_PROVINCIAS_LOADING'
export const NEW_FORM_PROVINCIAS_SUCCESS = 'NEW_FORM_PROVINCIAS_SUCCESS'
export const NEW_FORM_PROVINCIAS_ERROR = 'NEW_FORM_PROVINCIAS_ERROR'

export const NEW_FORM_LOCALIDADES_LOADING = 'NEW_FORM_LOCALIDADES_LOADING'
export const NEW_FORM_LOCALIDADES_SUCCESS = 'NEW_FORM_LOCALIDADES_SUCCESS'
export const NEW_FORM_LOCALIDADES_ERROR = 'NEW_FORM_LOCALIDADES_ERROR'

export const NEW_FORM_CODIGO_POSTAL_LOADING = 'NEW_FORM_CODIGO_POSTAL_LOADING'
export const NEW_FORM_CODIGO_POSTAL_SUCCESS = 'NEW_FORM_CODIGO_POSTAL_SUCCESS'
export const NEW_FORM_CODIGO_POSTAL_ERROR = 'NEW_FORM_CODIGO_POSTAL_ERROR'
export const NEW_FORM_CODIGO_POSTAL_RESET = 'NEW_FORM_CODIGO_POSTAL_RESET'

export const NEW_FORM_GETEMISIONES_LOADING = 'NEW_FORM_GETEMISIONES_LOADING'
export const NEW_FORM_GETEMISIONES_SUCCESS = 'NEW_FORM_GETEMISIONES_SUCCESS'
export const NEW_FORM_GETEMISIONES_ERROR = 'NEW_FORM_GETEMISIONES_ERROR'

export const NEW_FORM_PRODUCTOS_LOADING = 'NEW_FORM_PRODUCTOS_LOADING'
export const NEW_FORM_PRODUCTOS_SUCCESS = 'NEW_FORM_PRODUCTOS_SUCCESS'
export const NEW_FORM_PRODUCTOS_ERROR = 'NEW_FORM_PRODUCTOS_ERROR'

export const NEW_FORM_DOWNLOADPDF_LOADING = 'NEW_FORM_DOWNLOADPDF_LOADING'
export const NEW_FORM_DOWNLOADPDF_SUCCESS = 'NEW_FORM_DOWNLOADPDF_SUCCESS'
export const NEW_FORM_DOWNLOADPDF_ERROR = 'NEW_FORM_DOWNLOADPDF_ERROR'

export const NEW_FORM_FIND_USERS_LOADING = 'NEW_FORM_FIND_USERS_LOADING'
export const NEW_FORM_FIND_USERS_SUCCESS = 'NEW_FORM_FIND_USERS_SUCCESS'
export const NEW_FORM_FIND_USERS_ERROR = 'NEW_FORM_FIND_USERS_ERROR'

export const NEW_FORM_UPDATE_USER_LOADING = 'NEW_FORM_UPDATE_USER_LOADING'
export const NEW_FORM_UPDATE_USER_SUCCESS = 'NEW_FORM_UPDATE_USER_SUCCESS'
export const NEW_FORM_UPDATE_USER_ERROR = 'NEW_FORM_UPDATE_USER_ERROR'

export const NEW_FORM_DOWNLOAD_CSV_LOADING = 'NEW_FORM_DOWNLOAD_CSV_LOADING'
export const NEW_FORM_DOWNLOAD_CSV_SUCCESS = 'NEW_FORM_DOWNLOAD_CSV_SUCCESS'
export const NEW_FORM_DOWNLOAD_CSV_ERROR = 'NEW_FORM_DOWNLOAD_CSV_ERROR'

export const NEW_FORM_ENTIDADES_LOADING = 'NEW_FORM_ENTIDADES_LOADING'
export const NEW_FORM_ENTIDADES_SUCCESS = 'NEW_FORM_ENTIDADES_SUCCESS'
export const NEW_FORM_ENTIDADES_ERROR = 'NEW_FORM_ENTIDADES_ERROR'

export const NEW_FORM_RAZAS_LOADING = "NEW_FORM_RAZAS_LOADING"
export const NEW_FORM_RAZAS_SUCCESS = "NEW_FORM_RAZAS_SUCCESS"
export const NEW_FORM_RAZAS_ERROR = "NEW_FORM_RAZAS_ERROR"


export const NEW_FORM_CHECK_EMAIL_VALID_LOADING = 'NEW_FORM_CHECK_EMAIL_VALID_LOADING'
export const NEW_FORM_CHECK_EMAIL_VALID_SUCCESS = 'NEW_FORM_CHECK_EMAIL_VALID_SUCCESS'
export const NEW_FORM_CHECK_EMAIL_VALID_ERROR = 'NEW_FORM_CHECK_EMAIL_VALID_ERROR'

/**********************************/

export const RESET_NOTIFICATION_SERVICE_LOADING = 'RESET_NOTIFICATION_SERVICE_LOADING'

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'

/**********************************/

export const ENTITY_ALL = 'ALL'
export const PERM_MANAGER = 'PERM_MANAGER'
export const PERM_ADMIN = 'PERM_ADMIN'