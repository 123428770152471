import React, { useState, useEffect, useCallback } from 'react'
import { Button, Input,Select, Form, Grid, Header, Segment } from 'semantic-ui-react'
import * as CONSTANTS from '../constants/constants'
import NotificationServiceCnt from '../containers/NotificationServiceCnt';
import { parseOptions } from '../utils/parseEntities'
import SpinnerWithDimmerCmp from './SpinnerWithDimmerCmp'
import * as URLMAPPING from '../config/UrlMapping';
import Footer from "./FooterCmp";
import _ from "lodash"

function CreateUserCmp(props) {

    props.checkToken();

    const entityName = JSON.parse(localStorage.getItem('account')).entity_name.toUpperCase()
    const entityCode = JSON.parse(localStorage.getItem('account')).entity_code.toUpperCase()
    const entityId = JSON.parse(localStorage.getItem('account')).entity_id

    //const patternAlfanumericoWithSpaces = /^[0-9|a-z|A-Z| ]+$/
    const patternAlfanumerico = /^[0-9|a-z|A-Z]+$/
    const patternLetterWithSpaces = /^[a-z|A-Z| ]+$/
    const patternNumerico = /^\d+$/
    const patternEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
    const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    //const sinPattern = '';

    const [component, setComponent] = useState({
      nombre: {name: 'nombre', type: 'input', value : '', label:'Nombre', pattern: patternLetterWithSpaces, isInvalid: false, required: true},
      apellido: {name: 'apellido', type: 'input', value : '', label:'Apellido', pattern: patternLetterWithSpaces, isInvalid: false, required: true},
      username: {name: 'username', type: 'input', value : '', label:'Nombre de Usuario', pattern: patternAlfanumerico, isInvalid: false, required: true},
      email: {name: 'email', type: 'input', value : '', label:'E-mail', pattern: patternEmail, isInvalid: false, required: true},
      numeroSucursal: {name: 'numeroSucursal', type: 'input', value : '', label:'Número de Sucursal', pattern: patternNumerico, isInvalid: false, required: true},
      password: {name: 'password', type: 'password', value : '', label:'Contraseña', pattern: patternPassword, isInvalid: false, required: true},
      entidad: {name: 'entidad', type: 'select', value : '', label:'Entidad', pattern: '', isInvalid: false, required: true},
      esManager: {name: 'esManager', type: 'select', value : false, label:'¿Es Manager?', pattern: '', isInvalid: false, required: true},
      esAdmin: {name: 'esAdmin', type: 'select', value : false, label:'¿Es Admin?', pattern: '', isInvalid: false, required: true},
      confirmPassword: {name: 'confirmPassword', type: 'password', value : '', label:'Confirmar Contraseña', pattern: patternPassword, isInvalid: false, required: true}
  });
    
    const createUserPayload = useCallback(() =>{
            
      let entidad = entityCode === CONSTANTS.ENTITY_ALL ? component.entidad.value : entityId;
      
      return {
            username: component.username.value,
            password: component.password.value,
            email: component.email.value,
            name: component.nombre.value,
            lastname: component.apellido.value,
            entidadId: entidad,
            numeroSucursal: component.numeroSucursal.value,
            esManager: component.esManager.value,
            esAdmin: component.esAdmin.value
      }
    }, [component.apellido.value, component.email.value, component.entidad.value, component.esAdmin.value, component.esManager.value, component.nombre.value, component.numeroSucursal.value, component.password.value, component.username.value, entityCode, entityId])

    const redirectToLogin = useCallback(() => {
        props.history.push(URLMAPPING.LOGIN)
    }, [props.history])

    const [onLoad, setOnLoad] = useState(true)

    const [entidades] = useState([]);
    const [entidadesLockedOptions] = useState([{key: entityId, text: entityName, value: entityId}]);
    const [siNoOptions] = useState([{key: 'siOption', text: 'SI', value: true},{key: 'noOption', text: 'NO', value: false}]);

    const evalHasPermission = (permission, callback) => {
      let hasPermission = _.find(JSON.parse(localStorage.getItem('account')).authorizations, (item) =>{
        return item === permission}) !== undefined
      callback(hasPermission)
    }


    const [hasManagerPermission, setHasManagerPermission] = useState(false)
    const [hasAdminPermission, setHasAdminPermission] = useState(false)

    const onLoadFunction = () => {
      if(onLoad === true){
        evalHasPermission(CONSTANTS.PERM_MANAGER, setHasManagerPermission)
        evalHasPermission(CONSTANTS.PERM_ADMIN, setHasAdminPermission)
      }
      setOnLoad(false)
    }


        
        const [notificacionError, setNotificacionError] = useState(false);
        const [generando, setGenerando] = useState(false);
        const [validatingUser, setvalidatingUser] = useState(false);
        const [userExist, setUserExist] = useState(false);

        const resetForm = useCallback(() => {
          var newComponent = component;
          Object.keys(newComponent).forEach(targetName => {
            newComponent[targetName].value = '';
            setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
          });
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0
        },[component])

        useEffect(() => {
            const listener = event => {
              if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                document.getElementById('confirm').click();
              }
            };

            if (props.status === CONSTANTS.NOT_LOGGED) {
              redirectToLogin();
            }

            if(props.entidades === undefined && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_LOADING && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_ERROR){
              props.getEntidades();
            }

            if(entidades[0] === undefined && props.entidades !== undefined) {
              parseOptions(entidades, props.entidades)
            }

            if(props.userExist !== undefined && props.userExistStatus !== CONSTANTS.NEW_FORM_USER_EXIST_LOADING && validatingUser){
              setUserExist(props.userExist.exist);
              setGenerando(!props.userExist.exist);
              if (props.userExist.exist){
                notificationService('Usuario existente',`El usuario ${component.username.value} ya existe. Ingrese otro por favor.`,'error')
                component.username.error = `El usuario ${component.username.value} ya existe. Ingrese otro por favor.`;
              }else{
                props.createUser(createUserPayload());
              }
              setvalidatingUser(false);
            }

            if(props.user !== undefined && props.userStatus !== CONSTANTS.NEW_FORM_CREATE_USER_LOADING && generando && !userExist ){
              notificationService('Usuario creado',`El usuario ${component.username.value} se genero correctamente`,'success');
              resetForm();
              setGenerando(false)
            }

            document.addEventListener('keydown', listener);
            return () => {
              document.removeEventListener('keydown', listener);
            };
          }, [setUserExist, component.username.error, component.username.value, createUserPayload, entidades, generando, props, redirectToLogin, resetForm, userExist, validatingUser]);

          const usernameCreate = () =>{
            let nombre = component.nombre.value.toLowerCase();
            let apellido = component.apellido.value.toLowerCase();
            component.username.value = (nombre !== '' && apellido !== '') ? nombre.charAt(0) + apellido  : '';
            if (nombre !== '' && apellido !== '') component.username.error = validateComponent(component.username.name, component.username.value);
          }


          const handleChange = (event, callback) => {
            if(event === undefined){
              return;
            }

            let targetName=event.target.name;
            let targetValue=event.target.value;
            var newComponent = component;
            
            newComponent[targetName].error = validateComponent(targetName, targetValue);
            newComponent[targetName].value = targetValue;

            setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
          }

          const validateComponent = (targetName, targetValue) => {

            let required = validateRequiredError(targetName, targetValue);
            let valid = validatePattern(targetName, targetValue);

            let message = undefined;

            if(required === true)
              message = `El campo ${component[targetName].label} es requerido`;
            if(valid === true)
            message = (component[targetName].type === 'password') ? `Mínimo ocho caracteres, al menos una letra mayúscula, una letra minúscula y un número` : `El valor ${targetValue} es incorrecto`;
            return message;
          }

          const validateRequiredError = (targetName, targetValue) => {
            if(component[targetName].required === true && (targetValue === '' || targetValue === null ) ){
              return true;
            }
            return false;
          }

          const isFormValid =() => {
            if(iterateComponentsForEmptyRequireds()){
              notificationService('Ha ocurrido un error','Hay campos requeridos que no estan cargados.','error')
              return false;
            }

            if (!comparePasswords()){
              notificationService('Las contraseñas no coinciden','Vuelva a ingregar las contraseñas.','error');
              let msj = 'Las contraseñas no coinciden. Vuelva a ingresarlas';
              component.password.error = msj;
              component.confirmPassword.error = msj;
              return false;
            }

            if(props.checkEmailValid !== true){
              notificationService('Email existente', 'Ingrese otro email por favor.', 'error');
              let msj = 'Este email ya esta registrado';
              component.email.error = msj;
              return false;
            }

            if(iterateComponentsForInvalids()){
              notificationService('Ha ocurrido un error','Hay errores en el formulario, por favor verifiquelos y vuelva a intentarlo.','error')
              return false;
            }

            return true;
          }

          const usernameExist=() =>{
            let payload = {
              pathVariable: component.username.value
          }
          props.usernameExist(payload);
          }

          const iterateComponentsForInvalids = () => {
            let isInvalid = false;
            var newComponent = component;
            Object.keys(newComponent).forEach(targetName => {
              if(newComponent[targetName].error !== undefined){
                setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
                isInvalid = true
              }
            })
            return isInvalid;
          }

          const comparePasswords = () =>{
            let isInvalid = true;
            if(component.password.value !== component.confirmPassword.value){
              let newComponent = component;
              newComponent.password.value = ''
              newComponent.confirmPassword.value = ''
              setComponent(prevState => ({ ...prevState, [component.password.name]: newComponent.password }));
              setComponent(prevState => ({ ...prevState, [component.confirmPassword.name]: newComponent.confirmPassword }));
              isInvalid = false;
          }
          return isInvalid;
          }



          const iterateComponentsForEmptyRequireds = () => {

            let requiredError = false;
            var newComponent = component;
            Object.keys(newComponent).forEach(targetName => {
              //console.log(newComponent[targetName], newComponent[targetName].value, newComponent[targetName].type)
              if(newComponent[targetName].required === true && 
                (((newComponent[targetName].type === 'input' && newComponent[targetName].value.trim() === '') || 
                (newComponent[targetName].type === 'select' && newComponent[targetName].value === '')) ||
                (newComponent[targetName].type === 'password' && newComponent[targetName].value.trim() === ''))
                ){
                newComponent[targetName].error = `El campo ${newComponent[targetName].label} es requerido`
                setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
                requiredError = true
              }
            })
            return requiredError;
          }

          const [mensajeErrorNotification, setMensajeErrorNotification] = useState('');
          const [mensajeTitle, setMensajeTitle] = useState('');
          const [mensajeTipo, setMensajeTipo] = useState('');


          function notificationService(mensajeTitle, errorMessage, mensajeTipo){
            setMensajeTitle(mensajeTitle);
            setMensajeErrorNotification(errorMessage);
            setMensajeTipo(mensajeTipo);
            setNotificacionError(true);
            setTimeout(function(){ setNotificacionError(false); }, 1000);
        }

          function validatePattern(componentName, componentValue){
            if( component[componentName].pattern === '' ||
                (component[componentName].required === false && component[componentName].value === ''))
              return false;
            return !component[componentName].pattern.test(componentValue);
          }

          const handleChangeUsername = (event, callback) => {
            handleChange(event, callback);
            usernameCreate();
          }

          function onClickCancelar(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0
            window.location.reload();
          }

          function checkEmailValid(){
            props.checkEmail("{\"email\": \""+ component.email.value + "\"}");
          }

        function confirmAndRefresh(){
            if(isFormValid()){
              setvalidatingUser(true);
              usernameExist();
              return ;
            }
        }

        if(onLoad === true)
          onLoadFunction();

        return (
            <div>
              <NotificationServiceCnt open={notificacionError} titulo={mensajeTitle} texto={mensajeErrorNotification} tipo={mensajeTipo}/>
                <Grid textAlign='center' className={'create-new-user-labels'}>
                    <Grid.Row>
                    <Grid.Column >
                        <Header as='h2' textAlign='center' className='title-header'>Crear Nuevo Usuario</Header>
                        <Form size='large'>
                            <Segment stacked>
                              <Form.Field
                                control={Input}
                                fluid icon='address card outline'
                                iconPosition='left'
                                name={component.nombre.name}
                                value={component.nombre.value}
                                label={component.nombre.label}
                                placeholder={component.nombre.label}
                                onChange={handleChangeUsername}
                                error={component.nombre.error}
                              />
                              <Form.Field
                                fluid icon='address card outline'
                                iconPosition='left'
                                control={Input}
                                name={component.apellido.name}
                                value={component.apellido.value}
                                label={component.apellido.label}
                                placeholder={component.apellido.label}
                                onChange={handleChangeUsername}
                                error={component.apellido.error}
                              />
                              <Form.Field
                                fluid icon='mail'
                                iconPosition='left'
                                control={Input}
                                value={component.email.value}
                                name={component.email.name}
                                label={component.email.label}
                                placeholder={component.email.label}
                                onBlur={checkEmailValid}
                                onChange={handleChange}
                                error={component.email.error}
                                maxLength='100'
                              />
                              <Form.Field
                                fluid icon='user'
                                iconPosition='left'
                                control={Input}
                                name={component.username.name}
                                value={component.username.value}
                                label={component.username.label}
                                placeholder={component.username.label}
                                onChange={handleChange}
                                error={component.username.error}
                              />
                              {
                                entityCode === CONSTANTS.ENTITY_ALL ? 
                              <Form.Field
                                control={Select}
                                options={entidades}
                                value={component.entidad.value}
                                placeholder={component.entidad.label}
                                name={component.entidad.name}
                                label={component.entidad.label}
                                onChange={(e, o)=>handleChange({target:{name :component.entidad.name, value: o.value}})}
                                error={component.entidad.error}
                              /> : 
                              <Form.Field
                                control={Select}
                                options={entidadesLockedOptions}
                                value={component.entidad.value}
                                placeholder={component.entidad.label}
                                name={component.entidad.name}
                                label={component.entidad.label}
                                onChange={(e, o)=>handleChange({target:{name :component.entidad.name, value: o.value}})}
                                error={component.entidad.error}
                              />

                              }

                              {
                                hasManagerPermission === true ? 
                              <Form.Field
                                control={Select}
                                options={siNoOptions}
                                value={component.esManager.value}
                                placeholder={component.esManager.label}
                                name={component.esManager.name}
                                label={component.esManager.label}
                                onChange={(e, o)=>handleChange({target:{name :component.esManager.name, value: o.value}})}
                                error={component.esManager.error}
                              /> : 
                              null
                              }

                              {
                                hasAdminPermission === true ? 
                              <Form.Field
                                control={Select}
                                options={siNoOptions}
                                value={component.esAdmin.value}
                                placeholder={component.esAdmin.label}
                                name={component.esAdmin.name}
                                label={component.esAdmin.label}
                                onChange={(e, o)=>handleChange({target:{name :component.esAdmin.name, value: o.value}})}
                                error={component.esAdmin.error}
                              /> : 
                              null
                              }

                              <Form.Input
                                fluid icon='building'
                                iconPosition='left'
                                control={Input}
                                label={component.numeroSucursal.label}
                                placeholder={component.numeroSucursal.label}
                                onChange={(e)=>handleChange(e)}
                                name={component.numeroSucursal.name}
                                error={component.numeroSucursal.error}
                                value={component.numeroSucursal.value}
                              />
                              <Form.Input
                                fluid icon='lock'
                                iconPosition='left'
                                control={Input}
                                label={component.password.label}
                                placeholder={component.password.label}
                                type={component.password.type}
                                onChange={(e)=>handleChange(e)}
                                name={component.password.name}
                                error={component.password.error}
                                value={component.password.value}
                              />
                              <Form.Input
                                fluid icon='lock'
                                iconPosition='left'
                                control={Input}
                                label={component.confirmPassword.label}
                                placeholder={component.confirmPassword.label}
                                type={component.confirmPassword.type}
                                onChange={(e)=>handleChange(e)}
                                name={component.confirmPassword.name}
                                error={component.confirmPassword.error}
                                value={component.confirmPassword.value}
                              />
                              <Button color='grey' className={'button-submit'} size='large' onClick={()=>onClickCancelar()} type='button'>Cancelar</Button>
                              <Button id='confirm' className={'button-submit ' + (generando ? 'loading' : '') }  size='large' onClick={()=>confirmAndRefresh()} type='button' disabled={generando}>Confirmar</Button>
                            </Segment>
                        </Form>
                        
                    </Grid.Column>
                    </Grid.Row>
                    </Grid>

        <SpinnerWithDimmerCmp active={(generando && !userExist)} title={'Generando usuario ' + component.username.value+ '...'}/>
        {<Footer active={true}/>}
        </div>

        )
}

export default CreateUserCmp;
