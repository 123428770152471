import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)
setDefaultLocale('es');

function DatePickerCmp(props) {

    return (
        <div className={"field "+ (props.error !== undefined ? 'error' : '')}>
            <label style={{ display: "block", fontWeight: "bold" }}>
            {props.label}
            </label>
            <DatePicker
                className="react-datepicker-input"
                popperPlacement="top-start"
                locale="es"
                dateFormat="dd/MM/yyyy"
                selected={props.selected}
                placeholderText={props.placeholderText}
                name={props.name}
                onChange={(e)=>props.onChange({target:{name: props.name, value: e}})}
                maxDate={props.maxDate}
                minDate={props.minDate}
                showYearDropdown={true}
            />
            <div className={ props.error !== undefined ? "ui pointing above prompt label" : ''}>{props.error}</div>
        </div>
    )
}
export default DatePickerCmp