import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal } from "semantic-ui-react";
function ModalCoberturas(props) {

    const [openModal, setOpenModal] = useState(false);
    const [prevState, setPrevState] = useState(false);
    const [currentState, setCurrentState] = useState(true);
    const [items, setItems] = useState([]);

    function cerrarCoberturas(){
        setOpenModal(false)
    }

    const parseCoberturas = useCallback(() =>{
        let json = JSON.parse(props.productoPlan.information).descripcion
        generateItems(json)
    },[props.productoPlan.information])

    useEffect(() => {
        setCurrentState(props.show)
        if(props.show && currentState !== prevState && props.show === true){
            setOpenModal(true)
            
            setPrevState(props.show)
            parseCoberturas()
        }
    }, [props.show,currentState, prevState, parseCoberturas])

    /*const parseCoberturas = () =>{
            let json = JSON.parse(props.productoPlan.information).descripcion
            generateItems(json)
    }*/

    const generateItems = (json) => {
        let itemKey = 0
        setItems([])
        let arrayItems=[]
        json.forEach(function(elem){
            //console.log(elem)
            let itemType = '';
            if(elem.item === 'important')
                itemType = '*'
            if(elem.item === 'regular')
                itemType = '-'
            
                arrayItems.push(<div className={"modal-text"} key = {"modal-item-key-"+itemKey}>
                                <div>{itemType + " "}{elem.descripcion}</div>
                            </div>)
            itemKey++
            
        })
        setItems(arrayItems)
    }


    return (
        <div>
            <Modal open={openModal} centered={true} size="small" closeOnDocumentClick={true} closeOnDimmerClick={true}>
                <div className={"modal-x" } onClick={cerrarCoberturas}>X</div>
                <Modal.Header>Coberturas</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                    <div>{items}</div>
                    <div className={"buttons-align-right"}>
                        <Button color='red' size='small' onClick={()=>cerrarCoberturas()} type='button' ref={input => input && input.focus()}>Cerrar</Button>
                    </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>

    </div>
    );
}
export default ModalCoberturas