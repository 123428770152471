
import ListCmp from '../components/ListCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/loginAction')
const { getEmisiones, getEntidades } = require('../actions/homeAction')

const mapStateToProps = (state) => {
    return{
      emisionesStatus: state.homeReducer.emisionesStatus,
      emisiones: state.homeReducer.emisiones,
      status: state.loginReducer.status,
      entidades: state.homeReducer.entidades,
      entidadesStatus: state.homeReducer.entidadesStatus
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  getEmisiones: (payload) => {
    dispatch(getEmisiones(payload));
  },
  getEntidades: () => {
    dispatch(getEntidades());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(ListCmp);
