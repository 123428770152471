import React, { useState, useEffect } from "react";

export default function NotificationServiceCmp(props) {

    useEffect(() => {
        if (props.open === true) {
          notificar();
          props.restart();
        }
    })

    const [notificacion, setNotificacion] = useState("notification-not-show");
    
    function notificar(){
      //calculateCoordenates()
      setNotificacion("notificacion-show")
      setTimeout(function(){ closeNotification(); }, 5000);
    }
    function closeNotification(){
 
      setNotificacion("notification-not-showing")
      setTimeout(function(){ setNotificacion("notification-not-show"); }, 1000);
    }

    return (
        <div className={"notification-base tipo-notificacion-" + props.tipo + " " +  notificacion}>
            <div className="notification-close-x" onClick={closeNotification}>x</div>
            <p className="notification-title">{props.titulo}</p>
            <p className="notification-message">{props.texto}</p>
        </div>
    )
}

