import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Grid, Header, Segment, Label } from "semantic-ui-react";
import * as CONSTANTS from "../constants/constants";
import { withRouter } from 'react-router';
import { API_URL_FRONT } from "../config/config";
import * as URLMAPPING from '../config/UrlMapping';
import Footer from "./FooterCmp";


function HomeCmp(props) {
  props.checkToken();

  const [emision, setEmision] = useState('');

  const entityCode = JSON.parse(localStorage.getItem('account')).entity_code.toUpperCase()

  const redirectToLogin = useCallback(() => {
    props.history.push(URLMAPPING.LOGIN)
  },[props.history])

  useEffect(() => {
    if (props.status === CONSTANTS.NOT_LOGGED) {
      redirectToLogin();
    }

    if(props.pdfFile !== undefined){
        console.log("pdffile: ",props.pdfFile)
        
        let blob = new Blob([props.pdfFile], { type: 'application/pdf' });
        let url = URL.createObjectURL(blob);
        console.log(blob);
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `COMPROBANTE_${entityCode}.pdf`);
        document.body.appendChild(link);    
        link.click();
        document.body.removeChild(link);
      }
    if(props.emision !== undefined){
      setEmision(props.emision.response)
    }
  },[props.status, props.pdfFile, props.emision, redirectToLogin, entityCode])

  function descargarComprobante(){
    console.log("descarga comprobante")
    props.downloadPDF({pathVariable: {id: emision.id}})
  }

  function backToNewForm(){
    //props.history.push(URLMAPPING.CREATE)
    window.location.href = `${API_URL_FRONT}${URLMAPPING.CREATE}`;
  }

  return (
    <div>
                
                <div className={"form-centered"}>
                    <Grid.Row>
                    <Grid.Column>
                        <Header as='h2' color='red' className='title-header' textAlign='center'>Venta Procesada</Header>
                        <Form size='large'>
                            <Segment stacked>
                              <div className={"success-label-container"}>
                                <div><Label className={"success-label"}>Nombre: </Label> <p className={"success-item"}>{emision.name}</p></div>
                                <div><Label className={"success-label"}>Apellido: </Label> <p className={"success-item"}>{emision.surname}</p></div>
                                <div><Label className={"success-label"}>E-Mail: </Label> <p className={"success-item"}>{emision.email}</p></div>
                                <div><Label className={"success-label"}>Teléfono: </Label> <p className={"success-item"}>{emision.telephonePrefix} - {emision.telephone}</p></div>
                              </div>
                                <div className={"button-change-password-container"}>
                                    <Button color='red' className="button-change-password" size='large' onClick={()=>descargarComprobante()} type='button'>Descargar Comprobante</Button>
                                </div>
                                <div className={"button-change-password-container"}>
                                    <Button className="button-change-password" size='large' onClick={()=>backToNewForm()} type='button'>Volver</Button>
                                </div>
                                
                            </Segment>    
                        </Form>
                    </Grid.Column>
                    </Grid.Row>
                    </div>
              <Footer active={true}/>
            </div>
  );
}

export default withRouter(HomeCmp);
