
import NotificationServiceCmp from '../components/NotificationServiceCmp';

const { connect } = require('react-redux');

const { restart } = require('../actions/homeAction')

const mapStateToProps = (state) => {
    return{
      newState: state
    }
};

const mapDispatchToProps = dispatch => ({
  restart: () => {
    dispatch(restart());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(NotificationServiceCmp);
