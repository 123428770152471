const fullPathHref = window.location.href;

let mapEnvironments = [
    "test",
    "preprod",
]

const findEnvironmentVariables = () => {
    var localHref = fullPathHref.split(".")[0]; 

    /*si se agregan mas hosts locales para probar distintos estilos*/
    if( (localHref.search("localhost:3000") !== -1) || 
        (localHref.search("petshop:3000") !== -1) ){
        return "localhost";
    }
    let environment = mapEnvironments.find((item)=> fullPathHref.search(item) !== -1)
    if(environment === undefined)
        environment = "production"
    return environment;
}

const urlsLocalhost = () => {
    var retorno = {
        security: 'http://localhost:8082',
        backend: 'http://localhost:8120',
        frontend: `http://${fullPathHref.split("/")[2]}`,
        home: 'http://localhost:9333'
    }
    return retorno; 
}

const findUrls = (ambiente) => {

    if(ambiente === "localhost")
        return urlsLocalhost();

    var url = `https://${fullPathHref.split("/")[2]}`;
    var retorno = {
        security: `${url}/security`,
        backend: `${url}/services`,
        frontend: `${url}`,
        home: `${url}/docker_home`,
    }
    return retorno;
}

export const AMBIENTE = findUrls(findEnvironmentVariables());

export const API_URL = AMBIENTE.backend;
export const URL_LOGIN_FRONTEND = AMBIENTE.backend;
export const API_LOGIN_URL = AMBIENTE.security;
export const API_URL_FRONT = AMBIENTE.frontend;
export const URL_FRONT_HOME = AMBIENTE.home;
