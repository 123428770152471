import React, { useState, useEffect, useCallback } from 'react'
import { Button, Form, Header, Segment } from 'semantic-ui-react'
import * as CONSTANTS from '../constants/constants'
import * as URLMAPPING from '../config/UrlMapping';
import NotificationServiceCnt from "../containers/NotificationServiceCnt";
import Footer from "./FooterCmp";

function ChangePasswordLoggedCmp(props) {

    props.checkToken();

    let username = JSON.parse(localStorage.getItem('account')).username.toUpperCase();

    useEffect(() => {
      if (props.statusLogin === CONSTANTS.NOT_LOGGED) {
        redirectToLogin();
      }
    })

    function redirectToLogin(){
        props.history.push(URLMAPPING.LOGIN)
    }

    const patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
    const patternAlfanumerico = /^[0-9|a-z|A-Z]+$/
    

        const [component, setComponent] = useState({
          username: {name: 'username', type: 'input', value : '', label:'Usuario', pattern: patternAlfanumerico, isInvalid: false, required: false},
          passwordAnterior: {name: 'passwordAnterior', type: 'input', value : '', label:'Contraseña Anterior', pattern: patternAlfanumerico, isInvalid: false, required: true},
          password: {name: 'password', type: 'password', value : '', label:'Nueva Contraseña', pattern: patternPassword, isInvalid: false, required: true},
          confirmPassword: {name: 'confirmPassword', type: 'password', value : '', label:'Confirmar Contraseña', pattern: patternPassword, isInvalid: false, required: true}
        });

        const [notificacionError, setNotificacionError] = useState(false);
        const [cambiando, setCambiando] = useState(false);
        
        const resetForm = useCallback(() => {
          var newComponent = component;
          Object.keys(newComponent).forEach(targetName => {
            newComponent[targetName].value = '';
            setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
          });
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0
        },[component])

        useEffect(() => {
            const listener = event => {
              if (event.code === "Enter" || event.code === "NumpadEnter") {
                document.getElementById("confirm").click();
              }
            };

            if(cambiando && props.status === CONSTANTS.CHANGE_PASSWORD_LOGGED_ERROR){
              notificationService("No se pudo cambiar la contraseña","Verifique que la contraseña anterior sea correcta. De persistir el error consulte con el Administrador","error");
              setCambiando(false)
              resetForm();
            }

            if(cambiando && props.status === CONSTANTS.CHANGE_PASSWORD_LOGGED_SUCCESS){
              setCambiando(false);
              notificationService("Cambio realizado correctamente",`Se realizo el cambio de contraseña para el usuario ${username}. En instantes sera redireccionado al login.`,"success");
              resetForm();
              sleep(5000).then(() => {
                props.logout()
                //redirectToLogin();
              })
              
            }

            document.addEventListener("keydown", listener);
            return () => {
              document.removeEventListener("keydown", listener);
            };  
          }, [setCambiando, cambiando, props, resetForm, username]);

          function handleChange(event) {
            if(event === undefined){
                return;
              }
  
              let targetName=event.target.name;
              let targetValue=event.target.value;
              var newComponent = component;
              newComponent[targetName].error = validateComponent(targetName, targetValue);
              newComponent[targetName].value = targetValue;
  
              setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
        }

        const sleep = (milliseconds) => {
          return new Promise(resolve => setTimeout(resolve, milliseconds))
        }

        

        const validateComponent = (targetName, targetValue) => {

          let required = validateRequiredError(targetName, targetValue);
          let valid = validatePattern(targetName, targetValue);

          let message = undefined;

          if(required === true)
            message = `El campo ${component[targetName].label} es requerido`;
          if(valid === true)
          message = (component[targetName].type === 'password') ? `Mínimo ocho caracteres, al menos una letra mayúscula, una letra minúscula y un número` : `El valor ${targetValue} es incorrecto`;
          return message;
        }

        const validateRequiredError = (targetName, targetValue) => {
          if(component[targetName].required === true && (targetValue === '' || targetValue === null ) ){
            return true;
          }
          return false;
        }

        function validatePattern(componentName, componentValue){
          if( component[componentName].pattern === '' ||
              (component[componentName].required === false && component[componentName].value === ''))
            return false;
          return !component[componentName].pattern.test(componentValue);
        }

        const [mensajeErrorNotification, setMensajeErrorNotification] = useState('');
        const [mensajeTitle, setMensajeTitle] = useState('');
        const [mensajeTipo, setMensajeTipo] = useState('');

        function notificationService(mensajeTitle, errorMessage, mensajeTipo){
          setMensajeTitle(mensajeTitle);
          setMensajeErrorNotification(errorMessage);
          setMensajeTipo(mensajeTipo);
          setNotificacionError(true);
          setTimeout(function(){ setNotificacionError(false); }, 1000);
      }

      const isFormValid =() => {
        if(iterateComponentsForEmptyRequireds()){
          notificationService("Ha ocurrido un error","Hay campos requeridos que no estan cargados.","error")
          return false;
        }

        
        if (!comparePasswords()){
          notificationService("Las contraseñas no coinciden","Vuelva a ingregar las contraseñas.","error");
          let msj = 'Las contraseñas no coinciden. Vuelva a ingresarlas';
          component.password.error = msj;
          component.confirmPassword.error = msj;
          return false;
        }

        if(iterateComponentsForInvalids()){
          notificationService("Ha ocurrido un error","Hay errores en el formulario, por favor verifiquelos y vuelva a intentarlo.","error")
          return false;
        }

        return true;
      }

      const comparePasswords = () =>{
        let isInvalid = true;
        if(component.password.value !== component.confirmPassword.value){
          let newComponent = component;
          newComponent.password.value = ''
          newComponent.confirmPassword.value = ''
          setComponent(prevState => ({ ...prevState, [component.password.name]: newComponent.password }));
          setComponent(prevState => ({ ...prevState, [component.confirmPassword.name]: newComponent.confirmPassword }));
          isInvalid = false;
      }
      return isInvalid;
      }

      const iterateComponentsForEmptyRequireds = () => {

        let requiredError = false;
        var newComponent = component;
        Object.keys(newComponent).forEach(targetName => {
          if(newComponent[targetName].required === true && newComponent[targetName].value.trim() === ''){
            newComponent[targetName].error = `El campo ${newComponent[targetName].label} es requerido`
            setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
            requiredError = true
          }
        })
        return requiredError;
      }
      

      const iterateComponentsForInvalids = () => {
        let isInvalid = false;
        var newComponent = component;
        Object.keys(newComponent).forEach(targetName => {
          if(newComponent[targetName].error !== undefined){
            newComponent[targetName].error = `El valor ${newComponent[targetName].value} es incorrecto`
            setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
            isInvalid = true
          }
        })
        return isInvalid;
      }
      


        function confirmAndBackToLogin(){
          if(isFormValid()){
            setCambiando(true);
            let payload = {
              oldPassword: component.passwordAnterior.value,
              newPassword: component.password.value,
              pathVariable: username
          } 
          props.changePasswordLogged(payload)
          return;
          }
          setCambiando(false);
        }


        return (
            <div>

              <NotificationServiceCnt open={notificacionError} titulo={mensajeTitle} texto={mensajeErrorNotification} tipo={mensajeTipo}/>
                <div className={"form-centered"}>
                    
                        <Header as='h2' className='title-header' textAlign='center'>Cambiar Contraseña</Header>
                        <Form className="form-large">
                            <Segment stacked>
                                <Form.Field>Usuario: {username}</Form.Field>
                                <Form.Input 
                                fluid icon='lock' 
                                iconPosition='left'
                                label={component.passwordAnterior.label}
                                placeholder={component.passwordAnterior.label}
                                type='password'
                                onChange={(e)=>handleChange(e)} 
                                name={component.passwordAnterior.name} 
                                error={component.passwordAnterior.error}
                                value={component.passwordAnterior.value}
                                />
                                <Form.Input 
                                fluid icon='lock' 
                                iconPosition='left'
                                label={component.password.label}
                                placeholder={component.password.label}
                                type={component.password.type}
                                onChange={(e)=>handleChange(e)} 
                                name={component.password.name} 
                                error={component.password.error}
                                value={component.password.value}
                                />
                                <Form.Input 
                                fluid icon='lock' 
                                iconPosition='left' 
                                label={component.confirmPassword.label}
                                placeholder={component.confirmPassword.label} 
                                type={component.confirmPassword.type} 
                                onChange={(e)=>handleChange(e)} 
                                name={component.confirmPassword.name} 
                                error={component.confirmPassword.error}
                                value={component.confirmPassword.value}
                                />

<Button id="confirm" fluid size='large' onClick={()=>confirmAndBackToLogin()} type='button'>Confirmar</Button>
                            </Segment>    
                        </Form>
                        
                        

        
                    </div>
                    {<Footer active={true}/>}
            </div>
        )
}

export default ChangePasswordLoggedCmp;