
import ChangePasswordCmp from '../components/ChangePasswordCmp.js';

const { connect } = require('react-redux');

const { 
  changePassword
} = require('../actions/loginAction')

const mapStateToProps = (state) => {
    return{
      user: state.loginReducer.loginState.user,
      page: state.loginReducer.page,
      loginprops: state.loginReducer.result,
      status: state.changePasswordReducer.status
    }
};

const mapDispatchToProps = dispatch => ({
  changePassword: (payload) => {
    dispatch(changePassword(payload));
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordCmp);