
import ListAdminCmp from '../components/ListAdminCmp.js';

const { connect } = require('react-redux');

const { checkToken  } = require('../actions/loginAction')
const { getEntidades, findUsers, updateUser } = require('../actions/homeAction')

const mapStateToProps = (state) => {
    return{
      users: state.homeReducer.users,
      usersStatus: state.homeReducer.usersStatus,
      userUpdated: state.homeReducer.userUpdated,
      userUpdatedStatus: state.homeReducer.userUpdatedStatus,
      status: state.loginReducer.status,
      entidades: state.homeReducer.entidades,
      entidadesStatus: state.homeReducer.entidadesStatus
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  findUsers: (payload) => {
    dispatch(findUsers(payload));
  },
  updateUser: (payload) => {
    dispatch(updateUser(payload));
  },
  getEntidades: () => {
    dispatch(getEntidades());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(ListAdminCmp);
