import React, { useState, useEffect, useCallback } from 'react';
import { Button, Form, Select, Icon } from 'semantic-ui-react';
import DatePickerCmp from './DatePickerCmp'
import * as CONSTANTS from '../constants/constants';
import * as URLMAPPING from '../config/UrlMapping';
import { parseEntitySearch, getNameEntity } from '../utils/parseEntities'
import Footer from "./FooterCmp";
import _ from "lodash"

export default function ListCmp(props) {

    const entityCode = JSON.parse(localStorage.getItem('account')).entity_code.toUpperCase()
    const [showAccordion] = useState([]);

    let sinPattern = ''
    const initialState = {
        fechaDesde: {name: 'fechaDesde', type: 'datepicker', value : new Date(), label:'Desde', pattern: sinPattern, isInvalid: false, required: true},
        fechaHasta: {name: 'fechaHasta', type: 'datepicker', value : new Date(), label:'Hasta', pattern: sinPattern, isInvalid: false, required: true},
        entidad: {name: 'entidad', type: 'select', value : '', label:'Entidad', pattern: '', isInvalid: false, required: false}
    }
 
    const [component, setComponent] = useState(initialState);
    const [emisionesRows, setEmisionesRows] = useState([]);
    const [searchingEmisiones, setSearchingEmisiones] = useState(false);
    const [entidades] = useState([]);       

    const evaluateAccordion = useCallback((id) => {
        return showAccordion[id]
    },[showAccordion])

    const handleShowAccordion = useCallback((e, id) => {
        //showAccordion[id] = !showAccordion[id] 
        showAccordion[id] = showAccordion[id] === "show-accordion" ? "hide-accordion" : "show-accordion";
        document.getElementById("accordion-"+id).classList = "accordion-container "+ showAccordion[id]
        //console.log(document.getElementById("accordion-icon-"+id))
        document.getElementById("accordion-icon-"+id).classList = showAccordion[id] === "hide-accordion" ? "dropdown icon accordion-icon-close" : "dropdown icon accordion-icon-open"
        
        //console.log("showAccordion", showAccordion[id])
    },[showAccordion])

    const formatDate = useCallback((dateString) =>{
        //debugger;
        let date;
        date = new Date(dateString);
        let day = date.getDate();
        let month = date.getMonth()+1;
        let year = date.getFullYear();
        return zeroPad(day,2) + '/' + zeroPad(month,2) + '/' + year;
    },[])

    const setRow = useCallback((id, fullName, document, email, creationDate, productTitle, planTitle, empleado, entidadId, totalPrice) =>{
        showAccordion[id] = "hide-accordion";

        let retorno =  
                (<tr key={id} onClick={(e)=>handleShowAccordion(e, id)} className="">
                   <td data-label='N° Solicitud'><Icon id={"accordion-icon-"+ id} className={"accordion-icon-close"} name='dropdown'/>{id}</td>
                   <td data-label='Nombre y Apellido'>{fullName}</td>
                   <td data-label='DNI'>{document}</td>
                   <td data-label='e-Mail'>{email}</td>
                   <td data-label='Fecha de Venta'>{creationDate}</td>
                   <td data-label='Producto'>{productTitle}</td>
                   <td data-label='Plan'>{planTitle}</td>
                   <td data-label='Empleado'>{empleado}</td>
                   <td data-label="Entidad">{getNameEntity(entidadId, entidades)}</td> 
                   <td data-label='Importe Premio'>${totalPrice}</td>                   
               </tr>
               )

        return retorno;
    },[entidades, handleShowAccordion, showAccordion])
    
    const setRowError = useCallback((error) =>{
        return  <tr key={1}>
                    <td >{error}</td>
                </tr>
    }, [])



    const setAccordion = useCallback((element) => {
        //console.log("elements:", element.soldProductAttributes)
        if(element.soldProductAttributes !== undefined )
        return (
            <tr key={"accordion-tr-" + element.id} id={"accordion-" + element.id} className={evaluateAccordion(element.id)}>
                <td colSpan={12} key={"accordion-td-" + element.id}>
                {productAttributes(element.soldProductAttributes)}  
                </td>
            </tr>
        )
    },[evaluateAccordion])

    const handleChange = (event, callback) => {
        if(event === undefined){
          return;
        }
    
        let targetName=event.target.name;
        let targetValue=event.target.value;
        var newComponent = component;
        newComponent[targetName].value = targetValue;
        newComponent[targetName].error = undefined;
        setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
      }

    const redirectToLogin = useCallback(() =>{
        props.history.push(URLMAPPING.LOGIN)
    },[props.history])

    const parseEmisiones = useCallback(()=>{
        let array = [];
        props.emisiones.response.forEach(element=>{
          array.push(setRow(
              element.id,
              element.name + ' ' + element.surname,
              element.document,
              element.email,
              formatDate(element.creationDate),
              element.productTitle,
              element.planDescription,
              element.username,
              element.entityId,
              element.totalPrice))
          array.push(setAccordion(element))
        })
        
        if(array[0] === undefined){
            let error = 'No se encontraron registros'
            
            array.push(setRowError(error))
        }
    
        setEmisionesRows(array);
    }, [formatDate, props, setAccordion, setRow, setRowError]);

    useEffect(() => {
        if (props.status === CONSTANTS.NOT_LOGGED) {
          redirectToLogin();
        }
        if(searchingEmisiones && props.emisiones !== undefined && CONSTANTS.NEW_FORM_GETEMISIONES_LOADING !== props.emisionesStatus && props.emisionesStatus !== CONSTANTS.NEW_FORM_GETEMISIONES_ERROR){
          parseEmisiones();
          setSearchingEmisiones(false);
        }

        if(props.entidades === undefined && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_LOADING && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_ERROR){
          props.getEntidades();
          //console.log(props.entidades);
        }
        
        if(entidades[0] === undefined && props.entidades !== undefined) { 
          //console.log(props.entidades);
          parseEntitySearch(entidades, props.entidades);
        }
    }, [setSearchingEmisiones, entidades, parseEmisiones, props, redirectToLogin, searchingEmisiones])

    const inputsAreInvalid = () =>{
        var newComponent = component;
        let inputsAreValid = false;

        if(component.fechaDesde.value === ''){
            component.fechaDesde.error = 'Debe ingresar un valor'
            inputsAreValid = true
            setComponent(prevState => ({ ...prevState, [component.fechaDesde.name]: newComponent[component.fechaDesde.name] }));
        }
        if(component.fechaHasta.value === ''){
            component.fechaHasta.error = 'Debe ingresar un valor'
            inputsAreValid = true
            setComponent(prevState => ({ ...prevState, [component.fechaHasta.name]: newComponent[component.fechaHasta.name] }));
        }
        
        return inputsAreValid    
    }

    

      const zeroPad = (num, places) => String(num).padStart(places, '0')


    function getList(){

        if(inputsAreInvalid()){
            return;
        }

        setEmisionesRows([])
        let fechaDesde = component.fechaDesde.value;
        let fechaHasta = component.fechaHasta.value;
        let entityId = component.entidad.value;
        let payload = {
            pathVariable: {username: JSON.parse(localStorage.getItem('account')).username},
            payload: {from: fechaDesde, to: fechaHasta, entityId }
        }

        props.getEmisiones(payload)
        setSearchingEmisiones(true);
    }



    const productAttributes = array => {
        let arrayAccordion = []
        _.forEach(array , (item) =>{
            arrayAccordion.push(<div key={item.id} className="accordion-cell">
                                    <label className="accordion-label">{item.attributeLabel}:</label> 
                                    <div className="accordion-value">{item.attributeFormatted}</div>
                                </div>)
        })
       return arrayAccordion; 
    }

    return (
        <div>
            <React.Fragment>
                <Form className='form-large'>
                    <Form.Group widths='equal'>
                        {
                        entityCode === CONSTANTS.ENTITY_ALL ? 
                            <Form.Field
                                control={Select}
                                options={entidades}
                                value={component.entidad.value}
                                placeholder={component.entidad.label}
                                name={component.entidad.name}
                                label={component.entidad.label}
                                onChange={(e, o)=>handleChange({target:{name :component.entidad.name, value: o.value}})}
                                error={component.entidad.error}
                            /> : 
                            null
                        }

                        <DatePickerCmp 
                            error={component.fechaDesde.error}
                            label={component.fechaDesde.label} 
                            selected={component.fechaDesde.value}
                            placeholderText={component.fechaDesde.label}
                            name={component.fechaDesde.name}
                            onChange={handleChange}
                        />  
                        <DatePickerCmp 
                            error={component.fechaHasta.error}
                            label={component.fechaHasta.label} 
                            selected={component.fechaHasta.value}
                            placeholderText={component.fechaHasta.label}
                            name={component.fechaHasta.name}
                            onChange={handleChange}
                        />  
                        <Button size='small' fluid className={'boton-ver-coberturas'} onClick={()=>getList()} type='button'>Buscar</Button>
                    </Form.Group>
                </Form>
            </React.Fragment>
            <div className='container'>
                <div className={'section-title'}>Listado</div>
                {<table className='ui celled table'>
                    <thead>
                        <tr>
                            <th>N° Solicitud</th>
                            <th>Nombre y Apellido</th>
                            <th>DNI</th>
                            <th>e-Mail</th>
                            <th>Fecha de Venta</th>
                            <th>Producto</th>
                            <th>Plan</th>
                            <th>Empleado</th>
                            <th>Entidad</th>
                            <th>Importe Premio</th>
                        </tr>
                    </thead>
                    <tbody className="tbody-list">
                        {emisionesRows}
                    </tbody>
                    </table>}
            </div>
            {<Footer active={true}/>}
        </div>
    )
}