import React from "react";
import MenuCnt from "../containers/MenuCnt";
import ThemeSelector from "./themes/ThemeSelector"

export default function Layout({ children }) {
  return (
      <div id="layoutId">
        <MenuCnt />
        <div className={"body-container"}>
          <React.Fragment>
            <ThemeSelector/>
            {children}
          </React.Fragment>
        </div>
        <div id="footerId"></div>
      </div>
  );
}