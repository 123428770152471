
import OlvideMiClaveCmp from '../components/OlvideMiClaveCmp';
// import { changePassword } from '../actions/loginAction';

const { connect } = require('react-redux');

const { 
  enviarEmailChangePassword,
} = require('../actions/loginAction')

const mapStateToProps = (state) => {
    return{
      user: state.loginReducer.loginState.user,
      page: state.loginReducer.page,
      loginprops: state.loginReducer.result,
      forgotPassword: state.forgotPasswordReducer.forgotPassword,
      forgotPasswordStatus: state.forgotPasswordReducer.forgotPasswordStatus
    }
};

const mapDispatchToProps = dispatch => ({
  enviarEmailChangePassword: (params) => {
    dispatch(enviarEmailChangePassword(params));
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(OlvideMiClaveCmp);
