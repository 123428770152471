
import ChangePasswordLoggedCmp from '../components/ChangePasswordLoggedCmp.js';

const { connect } = require('react-redux');
const { logout, checkToken  } = require('../actions/loginAction')

const {changePasswordLogged} = require('../actions/loginAction')

const mapStateToProps = (state) => {
    return{
      //user: state.loginReducer.loginState.user,
      statusLogin: state.loginReducer.status,
      //page: state.loginReducer.page,
      //loginprops: state.loginReducer.result,
      status: state.changePasswordReducer.status
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  changePasswordLogged: (payload) => {
    dispatch(changePasswordLogged(payload));
  },
  logout: () => {
    dispatch(logout());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordLoggedCmp);
