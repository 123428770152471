import * as CONSTANTS from '../constants/constants'

export function login(payload) {
    return {
        type: CONSTANTS.LOGIN_LOADING,
        payload: payload
    }
}

export function logout() {
    return {
        type: CONSTANTS.LOGOUT_LOADING
    }
}

export function checkToken() {
    return {
        type: CONSTANTS.CHECK_TOKEN_LOADING
    }
}

export function enviarEmailChangePassword(payload){
    return {
    type: CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_LOADING,
    payload: payload
    }
}

export function changePassword(payload){
    return {
        type: CONSTANTS.CHANGE_PASSWORD_LOADING,
        payload: payload
    }
}

export function changePasswordLogged(payload){
    return {
        type: CONSTANTS.CHANGE_PASSWORD_LOGGED_LOADING,
        payload: payload
    }
}

export const actions = {
    login,
    checkToken, 
    logout,
    changePassword,
    changePasswordLogged
};