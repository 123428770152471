import React from "react";

function CheckBoxCmp(props) {



return (
            <div className="field">
                <label>{props.label}</label>
                <span className={"switch"}> 
                    <span className={`slider ${props.value === true ? "checked-custom" : ""}`  } 
                    onClick={props.onClick}> 
                    </span>
                </span>
            </div> 
    )
}

export default CheckBoxCmp