import React, {useState} from 'react';
const ThemePetShop = React.lazy(() => import('./ThemePetshop'));
const ThemeDefault = React.lazy(() => import('./ThemeDefault'));

const ThemeSelector = () => {

    const [entidades] = useState([
        {code: "petshop", component: <ThemePetShop/>}
    ])

    const getURL = () => {
        let localHref = window.location.href
        let estilo = entidades.find((entidad)=>{    
            if(localHref.search(entidad.code) !== -1)
                return entidad;
            return undefined
        })
        if(estilo === undefined)
            return <ThemeDefault/>
        return estilo.component;
    }

    return  (   
                <div>
                    <React.Suspense fallback={<></>}>
                        { getURL() }
                    </React.Suspense>
                </div>
            )
};

export default ThemeSelector;