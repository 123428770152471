import React, { useState, useEffect, useCallback } from "react";
import * as CONSTANTS from "../constants/constants";
import DatePickerCmp from "./DatePickerCmp"
import { Button, Form, Select } from "semantic-ui-react";
import * as URLMAPPING from '../config/UrlMapping';
import { parseEntitySearch } from '../utils/parseEntities'
import Footer from "./FooterCmp";

export default function DownloadCSVCmp(props) {

    const entityCode = JSON.parse(localStorage.getItem('account')).entity_code.toUpperCase()

    let sinPattern = ''
    const initialState = {
        fecha: {name: 'fecha', type: 'datepicker', value : '', label:'Fecha', pattern: sinPattern, isInvalid: false, required: true},
        entidad: {name: 'entidad', type: 'select', value : '', label:'Entidad', pattern: '', isInvalid: false, required: false}
    }

    const [component, setComponent] = useState(initialState);
    const [downloadFile, setDownloadFile] = useState({ loading: false , file: [] });
    const [entidades] = useState([]);

    const handleChange = (event, callback) => {
        if(event === undefined){
          return;
        }
    
        let targetName=event.target.name;
        let targetValue=event.target.value;
        var newComponent = component;
        newComponent[targetName].value = targetValue;
        newComponent[targetName].error = undefined;
        setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
      }

    const redirectToLogin = useCallback(() =>{
        props.history.push(URLMAPPING.LOGIN)
    },[props.history])


    useEffect(() => {
        if (props.status === CONSTANTS.NOT_LOGGED) {
          redirectToLogin();
        }

        if(props.csvFile !== downloadFile.file &&
          props.csvFileState !== CONSTANTS.NEW_FORM_DOWNLOAD_CSV_LOADING &&
          props.csvFileState !== CONSTANTS.NEW_FORM_DOWNLOAD_CSV_ERROR)
          setDownloadFile({ ...downloadFile, file: props.csvFile })

        if(downloadFile.loading && props.csvFile !== downloadFile.file &&
          props.csvFileState !== CONSTANTS.NEW_FORM_DOWNLOAD_CSV_LOADING &&
          props.csvFileState !== CONSTANTS.NEW_FORM_DOWNLOAD_CSV_ERROR
          ){
              setDownloadFile({ ...downloadFile, loading: false })
              console.log("csvFile: ",props.csvFile)
              
              let blob = new Blob([props.csvFile], { type: 'text/csv' });
              let url = URL.createObjectURL(blob);
              console.log(blob);
              var link = document.createElement('a');
              link.href = url;
              let fecha =  component.fecha.value
              let dateParsed = fecha.getFullYear() + "" + (fecha.getMonth()+1).toString().padStart(2, "0") + "" + fecha.getDate().toString().padStart(2, "0");
              link.setAttribute('download', `VentaEMI_${dateParsed}.csv`);
              document.body.appendChild(link);    
              link.click();
              document.body.removeChild(link);
          }
        
        if(props.entidades === undefined && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_LOADING && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_ERROR){
          props.getEntidades();
        }

        if(entidades[0] === undefined && props.entidades !== undefined) { 
          parseEntitySearch(entidades, props.entidades);
        }
    }, [setDownloadFile, component.fecha.value, downloadFile, entidades, entityCode, props, redirectToLogin])

    const getCSV = () => {
      let fecha =  component.fecha;
      let entityId = component.entidad.value;
        if(fecha.value === ''){
            let newComponent = component;
            fecha.error = 'Debe ingresar un valor'
            setComponent(prevState => ({ ...prevState, [fecha.name]: newComponent[fecha.name] }));
            return;
        }
        if (entityId === '')
          entityId = null

        let dateParsed = fecha.value.getFullYear() + '-' + (fecha.value.getMonth()+1) + '-' + fecha.value.getDate()
        let payload = { fecha: dateParsed, entityId }
        props.downloadCSV(payload);
        setDownloadFile({ ...downloadFile, loading: true })
    }


   

    return (
        <div>
            <React.Fragment>
                <Form className="form-large">
                    <Form.Group widths="equal">
                        {
                        entityCode === CONSTANTS.ENTITY_ALL ? 
                            <Form.Field
                                control={Select}
                                options={entidades}
                                value={component.entidad.value}
                                placeholder={component.entidad.label}
                                name={component.entidad.name}
                                label={component.entidad.label}
                                onChange={(e, o)=>handleChange({target:{name :component.entidad.name, value: o.value}})}
                                error={component.entidad.error}
                            /> : 
                            null
                        }
                        <DatePickerCmp 
                            error={component.fecha.error}
                            label={component.fecha.label} 
                            selected={component.fecha.value}
                            placeholderText={component.fecha.label}
                            name={component.fecha.name}
                            onChange={handleChange}
                        />  
                        <Button size='small' fluid className={"boton-ver-coberturas"} onClick={()=>getCSV()} type='button'>Descargar</Button>
                    </Form.Group>
                </Form>
            </React.Fragment>
            {<Footer active={true}/>}
        </div>
    )
}