import React, { useEffect, useState, useCallback } from 'react'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'
import * as URLMAPPING from '../config/UrlMapping';
import NotificationServiceCnt from "../containers/NotificationServiceCnt";
import * as CONSTANTS from "../constants/constants";
import ThemeSelector from "./themes/ThemeSelector"

function OlvideMiClaveCmp(props) {

  const patternEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/


        const [component, setComponent] = useState({
            email: {name: 'email', type: 'input', value : '', label:'E-mail', pattern: patternEmail, isInvalid: false, required: true},
        });

        const [enviado, setEnviado] = useState(false);
        const [formSubmited, setFormSubmited] = useState(false);
        const [enviando, setEnviando] = useState(false);
        const [notificacionError, setNotificacionError] = useState(false);


        const handleChange = (event) => {
            if(event === undefined){
              return;
            }

            let targetName=event.target.name;
            let targetValue=event.target.value;
            var newComponent = component;
            if(formSubmited === true)
              newComponent[targetName].error = validateComponent(targetName, targetValue);
            newComponent[targetName].value = targetValue;

            setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
          }

        const validateEmail = () =>{
          
          var newComponent = component;
          newComponent["email"].error = validateComponent("email", newComponent.email.value)
          setComponent(prevState => ({ ...prevState, email: newComponent.email }));
          return newComponent["email"].error === undefined
        }

        const emailIncorrecto = useCallback(() => {
          var newComponent = component;
          newComponent["email"].error = 'El email es incorrecto'
          setComponent(prevState => ({ ...prevState, email: newComponent.email }));
        },[component])

        function sendEmail(){
            setFormSubmited(true)
            if(isFormValid() && validateEmail()){
              setEnviando(true)
              setEnviado(true)
            props.enviarEmailChangePassword("{\"email\": \""+ component.email.value + "\"}")
            //console.log("send email change password: ", component.email.value)
            return ; 
        }
        }

        const iterateComponentsForEmptyRequireds = () => {

            let requiredError = false;
            var newComponent = component;
            Object.keys(newComponent).forEach(targetName => {
              if(newComponent[targetName].required === true && newComponent[targetName].value.trim() === ''){
                newComponent[targetName].error = `El campo ${newComponent[targetName].label} es requerido`
                setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
                requiredError = true
              }
            })
            return requiredError;
          }

          const [mensajeErrorNotification, setMensajeErrorNotification] = useState('');
          const [mensajeTitle, setMensajeTitle] = useState('');
          const [mensajeTipo, setMensajeTipo] = useState('');

          function notificationService(mensajeTitle, errorMessage, mensajeTipo){
            setMensajeTitle(mensajeTitle);
            setMensajeErrorNotification(errorMessage);
            setMensajeTipo(mensajeTipo);
            setNotificacionError(true);
            setTimeout(function(){ setNotificacionError(false); }, 1000);
        }

        const validateComponent = (targetName, targetValue) => {

            let required = validateRequiredError(targetName, targetValue);
            let valid = validatePattern(targetName, targetValue);

            let message = undefined;

            if(required === true)
              message = `El campo ${component[targetName].label} es requerido`;
            if(valid === true)
            message = `El valor ${targetValue} es incorrecto`;
            return message;
          }

          const validateRequiredError = (targetName, targetValue) => {
            if(component[targetName].required === true && (targetValue === '' || targetValue === null ) ){
              return true;
            }
            return false;
          }

          function validatePattern(componentName, componentValue){
            if( component[componentName].pattern === '' ||
                (component[componentName].required === false && component[componentName].value === ''))
              return false;
            return !component[componentName].pattern.test(componentValue);
          }

          const iterateComponentsForInvalids = () => {
            let isInvalid = false;
            var newComponent = component;
            Object.keys(newComponent).forEach(targetName => {
              if(newComponent[targetName].error !== undefined){
                newComponent[targetName].error = `El valor ${newComponent[targetName].value} es incorrecto. Por favor ingrese un e-mail valido.`
                setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
                isInvalid = true
              }
            })
            return isInvalid;
          }

        const isFormValid =() => {
            if(iterateComponentsForEmptyRequireds()){
              notificationService("Ha ocurrido un error","Hay campos requeridos que no estan cargados.","error")
              return false;
            }

            if(iterateComponentsForInvalids()){
              notificationService("Ha ocurrido un error","Hay errores en el formulario, por favor verifiquelos y vuelva a intentarlo.","error")
              return false;
            }

            return true;
          }

        function backToLogin(){
            props.history.push(URLMAPPING.LOGIN)
        }
        function showOnSent(){
            return enviado ? '' : ' not-show'
        }

        function notShowOnSent(){
            return enviado ? ' not-show' : '' 
        }

        useEffect(() => {
          if(enviando === true && props.forgotPasswordStatus === CONSTANTS.SENT_MAIL_CHANGE_PASSWORD_ERROR){
            console.log("forgotPasswordStatus: ",props.forgotPasswordStatus)
            setEnviando(false)
            setEnviado(false)
            emailIncorrecto()
          }
        }, [setEnviando, emailIncorrecto, enviando, props.forgotPasswordStatus])

        return (
            <div>
              <ThemeSelector/>
              <NotificationServiceCnt open={notificacionError} titulo={mensajeTitle} texto={mensajeErrorNotification} tipo={mensajeTipo}/>
                <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
                    <Grid.Row>
                    <Grid.Column style={{ maxWidth: 450 }} >
                        <Header as='h2' className='title-header' textAlign='center'>Olvide Mi Clave</Header>
                        <Form size='large'>
                            <Segment stacked>
                                <Form.Input 
                                fluid icon='mail'
                                iconPosition='left'
                                //value={component.email.value}
                                name={component.email.name}
                                label={component.email.label}
                                placeholder={component.email.label}
                                onChange={(e)=>handleChange(e)} 
                                error={component.email.error}
                                className={notShowOnSent()}
                                maxLength="100"
                                autoComplete="off"
                                />
                                <p className={showOnSent()}>E-Mail enviado a: {component.email.value}</p>
                                <div className={"button-change-password-container"}>
                                    <Button color='grey' className="button-change-password" size='large' onClick={()=>backToLogin()} type='button'>Volver al Login</Button>
                                </div>
                                <div className={"button-change-password-container " + notShowOnSent()}>
                                    <Button color='red' className="button-change-password" size='large' onClick={()=>sendEmail()} type='button'>Enviar E-Mail</Button>
                                </div>

                                <div className={"button-change-password-container " + showOnSent()}>
                                    <Button color='red' className="button-change-password" size='large' onClick={()=>sendEmail()} type='button'>Volver a enviar</Button>
                                </div>
                                
                            </Segment>    
                        </Form>
                    </Grid.Column>
                    </Grid.Row>
                    </Grid>
            </div>
        )
}

export default OlvideMiClaveCmp;