import React,{ useEffect } from "react";
import pdfPoliticaDePrivacidad from "../assets/politica_de_privacidad.pdf"
import terminos_y_condiciones from "../assets/terminos_y_condiciones.pdf"
import DNPDP from "../assets/DNPDP.pdf"
import * as ReactDOM from 'react-dom'

const Footer = (props) => {

    const funcFooter = (active) => {
        let footer = (  <div className="footer ui three column grid">
                            <div className="column">
                                <div className="segment">
                                    <a href={pdfPoliticaDePrivacidad}>Política de privacidad del Sitio Web</a>
                                </div>
                            </div>
                            <div className="column">
                                <div className="segment">
                                    <a href={terminos_y_condiciones}>Terminos y Condiciones</a>
                                </div>
                            </div>
                            <div className="column">
                                <div className="segment">
                                    <a href={DNPDP}>Derecho de acceso disposición DNPDP N°10/08 </a>
                                </div>
                            </div>
                            <div className="footer-bottom">
                                © 2020 Colon Asistencias - Todos los derechos reservados.
                            </div>
                        </div>)
        if(document.getElementById('footerId'))
            ReactDOM.render(active === true ? footer : null, document.getElementById('footerId'));
        
        return footer
    }

    useEffect(() => {
        if(props){
            funcFooter(props.active)
        }
    })

    

    return null
    
}

export default Footer