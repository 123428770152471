import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Input, Select } from "semantic-ui-react";
import CheckBoxCmp from './CheckBoxCmp';
import * as CONSTANTS from "../constants/constants";
import * as URLMAPPING from '../config/UrlMapping';
import { parseEntitySearch, getNameEntity } from '../utils/parseEntities'
import Footer from "./FooterCmp";

//import SpinnerWithDimmerCmp from './SpinnerWithDimmerCmp';

export default function ListAdmin(props) {

  const entityCode = JSON.parse(localStorage.getItem('account')).entity_code.toUpperCase()

    const [component, setComponent] = useState({
        buscar: {name: 'buscar', type: 'input', label:'Buscar', value:'', placeholder:"Buscar por Nombre, Apellido, Username o Sucursal"},
        entidad: {name: 'entidad', type: 'select', value : '', label:'Entidad', pattern: '', isInvalid: false, required: false}
    });

const [switchList, setSwitchList] = useState({});
const [changeSwitch, setChangeSwitch] = useState();
const [searchingUsers, setSearchingUsers] = useState();
const [users, setUsers] = useState();
const [entidades] = useState([]);

const disableUser = useCallback((id) =>{

    props.updateUser({pathVariable: {id: id}, body : {enabled: !switchList[id]}})
    setChangeSwitch(true)
}, [props, switchList])

const setRow = useCallback((element) =>{
    switchList[element.id] = element.enabled
    return <tr key={element.id}>
               <td data-label="Id">{element.id}</td>
               <td data-label="Nombre">{element.name}</td>
               <td data-label="Apellido">{element.lastname}</td>
               <td data-label="Username">{element.username}</td>
               <td data-label="Sucursal">{element.numeroSucursal}</td>
               { entityCode === CONSTANTS.ENTITY_ALL ?
                <td data-label="Entidad">{getNameEntity(element.entidadId, entidades)}</td> :
                null }
               <td data-label="Habilitado">
                    <CheckBoxCmp value={switchList[element.id]} onClick={()=>disableUser(element.id)}/>
               </td>
           </tr>
},[disableUser, entidades, entityCode, switchList])

const findUsers = useCallback(() => {
    let payload = {
        query: { 
            search: component.buscar.value,
            entityId: component.entidad.value
        }
    } 
    props.findUsers(payload);
    setSearchingUsers(true);
},[component.buscar.value, component.entidad.value, props])

const parseUsers = useCallback(()=>{

    //console.log("parseUsers")
    let array = [];
    
    props.users.forEach(element=>{
        
        array.push(setRow(element))
    })
    
    if(array[0] === undefined){
        let error = "No se encontraron registros"
        
        array.push(setRowError(error))
    }
    setUsers(array)
},[props.users, setRow])

const redirectToLogin = useCallback(() =>{
    props.history.push(URLMAPPING.LOGIN)
  },[props.history])

useEffect(() => {

    if (props.status === CONSTANTS.NOT_LOGGED) {
        redirectToLogin();
      }
    
    if(searchingUsers && props.users !== undefined && props.usersStatus !== CONSTANTS.NEW_FORM_FIND_USERS_LOADING && props.usersStatus !== CONSTANTS.NEW_FORM_FIND_USERS_ERROR){
        parseUsers()
        setSearchingUsers(false)
    }

    if(changeSwitch &&  props.userUpdated !== undefined && props.userUpdatedStatus !== CONSTANTS.NEW_FORM_UPDATE_USER_LOADING && props.usersStatus !== CONSTANTS.NEW_FORM_UPDATE_USER_ERROR){
        setSwitchList({})
        findUsers()
        setChangeSwitch(false)
    }

    if(props.entidades === undefined && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_LOADING && props.entidadesStatus !== CONSTANTS.NEW_FORM_ENTIDADES_ERROR){
      props.getEntidades();
    }

    if(entidades[0] === undefined && props.entidades !== undefined) { 
      parseEntitySearch(entidades, props.entidades);
    }

},[setSearchingUsers, changeSwitch, entidades, findUsers, parseUsers, props, redirectToLogin, searchingUsers])


const handleChange = (event) => {
    if(event === undefined){
      return;
    }
    let targetName=event.target.name;
    let targetValue=event.target.value;
    var newComponent = component;
    newComponent[targetName].value = targetValue;
    setComponent(prevState => ({ ...prevState, [targetName]: newComponent[targetName] }));
  }





const setRowError = (error) =>{
    return  <tr key={1}>
                <td >{error}</td>
            </tr>
}

    return(
        <div>
        <div className="container">
            <div className={"section-title"}>Listado de Usuarios</div>
            <React.Fragment>
            <Form className="form-large">
                <Form.Group widths="equal">
                {
                  entityCode === CONSTANTS.ENTITY_ALL ? 
                      <Form.Field
                          control={Select}
                          options={entidades}
                          value={component.entidad.value}
                          placeholder={component.entidad.label}
                          name={component.entidad.name}
                          label={component.entidad.label}
                          onChange={(e, o)=>handleChange({target:{name :component.entidad.name, value: o.value}})}
                          error={component.entidad.error}
                      /> : 
                      null
                }
                <Form.Field
                  control={Input}
                  value={component.buscar.value}
                  name={component.buscar.name}
                  label={component.buscar.label}
                  placeholder={component.buscar.placeholder}
                  fluid icon='search'
                  onChange={(e)=>handleChange(e)} 
                  />
                <Button size='small' fluid className={"boton-ver-coberturas"} onClick={()=>findUsers()} type='button'>Buscar</Button>
                </Form.Group>
            </Form>
        </React.Fragment>
            <table className="ui celled table">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Apellido</th>
                        <th>Username</th>
                        <th>Sucursal</th>
                        {entityCode === CONSTANTS.ENTITY_ALL ? <th>Entidad</th> : null}
                        <th>Habilitado</th>
                    </tr>
                </thead>
                <tbody>
                    {users}
                </tbody>
            </table>
        </div>
        {<Footer active={true}/>}
    </div>
    )
}