import React, { useState, useEffect, useCallback } from "react";
//import _ from 'lodash'
import * as CONSTANTS from "../constants/constants";
import * as URLMAPPING from '../config/UrlMapping';
import Footer from "./FooterCmp";
import { URL_FRONT_HOME } from "../config/config";

function HomeCmp(props) {
  props.checkToken();

  const redirectToLogin = useCallback(() =>{
    props.history.push(URLMAPPING.LOGIN)
  }, [props.history])
  

  const [showContainer, setShowContainer] = useState();

  useEffect(() => {
    let updateContainer = true
    if (props.status === CONSTANTS.NOT_LOGGED) {
      redirectToLogin();
    }

    document.addEventListener("mousemove", (e) => {
      if(updateContainer === true){
        if( e.offsetY <= 65){
          setShowContainer("body-container-hide")
        }
        else{
          setShowContainer("body-container-show")
        }
      }
    });

    return function cleanup() {
      updateContainer = false
    }
  }, [props, redirectToLogin])



  



  //const entityName = JSON.parse(localStorage.getItem('account')).entity_name.toUpperCase()
  const entityCode = JSON.parse(localStorage.getItem('account')).entity_code.toUpperCase()
  //const entityId = JSON.parse(localStorage.getItem('account')).entity_id
  //console.log("entityCode", entityCode)

  return (
    <div className={showContainer}>
        {<object  
          id="home-page-id"
          className={"home-page"}
          data={URL_FRONT_HOME +"/" + entityCode + ".html"}
          type="text/html" typemustmatch="true">
        </object>}
        {<Footer active={false}/>}
    </div>
    
  );
}

export default HomeCmp;
