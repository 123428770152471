import React, { useState, useEffect, useCallback } from "react";
import { Button, Form, Input, Select, Label, Message } from "semantic-ui-react";
import * as CONSTANTS from "../constants/constants";
import ModalCoberturas from "./ModalCoberturasCmp";
import SelectizeCmp from "./SelectizeCmp";
import SpinnerWithDimmerCmp from "./SpinnerWithDimmerCmp";
import DatePickerCmp from "./DatePickerCmp";
import NotificationServiceCnt from "../containers/NotificationServiceCnt";
import _ from "lodash";
import * as URLMAPPING from "../config/UrlMapping";
import Footer from "./FooterCmp";
import OptionSelectCmp from "./UI/OptionSelectCmp";
import { searchCodigoPostal } from "../sagas/sagas";

const sexoOptions = [
  { key: "MASCULINO", text: "MASCULINO", value: "M" },
  { key: "FEMENINO", text: "FEMENINO", value: "F" },
];

const sexoMascotaOptions = [
  { key: "MACHO", text: "Macho", value: "Macho" },
  { key: "HEMBRA", text: "Hembra", value: "Hembra" },
];

const vacunasAlDiaOptions = [
  { key: "SI", text: "Si", value: "SI" },
  { key: "NO", text: "No", value: "NO" },
];

const petTypeOptions = [
  { key: "PERRO", text: "Perro", value: "PERRO" },
  { key: "GATO", text: "Gato", value: "GATO" },
];

const tarjetasOptions = [
  { key: "VISA", text: "VISA", value: "1" },
  { key: "MASTERCARD", text: "MASTERCARD", value: "2" },
  { key: "AMEX", text: "AMEX", value: "19" },
];

const formasPagoOptions = [
  { key: "1", text: "TARJETA DE CREDITO", value: "3" },
  { key: "2", text: "CBU", value: "6" },
];

const tipoIdentificacionOptions = [{ key: "1", text: "DNI", value: "1" }];

const NewFormCmp = (props) => {
  const patternAlfanumerico = /^[0-9|a-z|A-Z|ñÑ]+$/;
  const patternAlfanumericoWithSpaces = /^[0-9|a-z|A-Z| |.|ñÑ]+$/;
  const patternNumerico = /^\d+$/;
  //const patternPrecio = /^\d+(.\d{1,2})?$/
  const patternEmail =
    /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  const sinPattern = "";

  const initialState = {
    producto: {
      name: "producto",
      type: "input",
      value: "",
      label: "Producto",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    plan: {
      name: "plan",
      type: "input",
      value: "",
      label: "Plan",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
      disabled: true,
    },
    nombreMascota: {
      name: "nombreMascota",
      type: "input",
      value: "",
      label: "Nombre de la Mascota",
      pattern: patternAlfanumericoWithSpaces,
      isInvalid: false,
      required: true,
    },
    razaCustom: {
      name: "razaCustom",
      type: "input",
      value: "",
      label: "Si no se encuentra la raza, especifique acá",
      placeholder: "Escriba la raza",
      pattern: patternAlfanumericoWithSpaces,
      isInvalid: false,
      required: false,
    },
    petType: {
      name: "petType",
      type: "input",
      value: "PERRO",
      label: "Tipo Mascota",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    sexoMascota: {
      name: "sexoMascota",
      type: "input",
      value: "M",
      label: "Sexo Mascota",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    raza: {
      name: "raza",
      type: "input",
      value: "",
      label: "Raza",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    fechaNacimientoMascota: {
      name: "fechaNacimientoMascota",
      type: "datepicker",
      value: "",
      label: "Fecha de Nacimiento Mascota",
      placeholder: "Fecha de Nacimiento",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    vacunasAlDia: {
      name: "vacunasAlDia",
      type: "input",
      value: "SI",
      label: "Tiene las vacunas al día?",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    mascotaEsDeSeguridad: {
      name: "mascotaEsDeSeguridad",
      type: "input",
      value: "NO",
      label: "La mascota es de seguridad/vigilancia?",
      placeholder: "Es seguridad/vigilancia?",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    tipoIdentificacion: {
      name: "tipoIdentificacion",
      type: "input",
      value: "1",
      label: "Tipo Identificación",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    dni: {
      name: "dni",
      type: "",
      value: "",
      label: "Número Identificación",
      pattern: patternNumerico,
      isInvalid: false,
      required: true,
      minLength: 7,
    },
    nombre: {
      name: "nombre",
      type: "input",
      value: "",
      label: "Nombre",
      pattern: patternAlfanumericoWithSpaces,
      isInvalid: false,
      required: true,
    },
    apellido: {
      name: "apellido",
      type: "input",
      value: "",
      label: "Apellido",
      pattern: patternAlfanumericoWithSpaces,
      isInvalid: false,
      required: true,
    },
    fechaNacimiento: {
      name: "fechaNacimiento",
      type: "datepicker",
      value: "",
      label: "Fecha de Nacimiento",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    sexo: {
      name: "sexo",
      type: "input",
      value: "",
      label: "Sexo",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    email: {
      name: "email",
      type: "input",
      value: "",
      label: "E-mail",
      pattern: patternEmail,
      isInvalid: false,
      required: true,
      minLength: 6,
    },
    area: {
      name: "area",
      type: "input",
      value: "",
      label: "Area",
      pattern: patternNumerico,
      isInvalid: false,
      required: true,
      minLength: 2,
    },
    telefono: {
      name: "telefono",
      type: "input",
      value: "",
      label: "Teléfono",
      pattern: patternNumerico,
      isInvalid: false,
      required: true,
      minLength: 6,
    },
    calle: {
      name: "calle",
      type: "input",
      value: "",
      label: "Calle",
      pattern: patternAlfanumericoWithSpaces,
      isInvalid: false,
      required: true,
    },
    calleNumero: {
      name: "calleNumero",
      type: "input",
      value: "",
      label: "Número",
      pattern: patternNumerico,
      isInvalid: false,
      required: true,
    },
    piso: {
      name: "piso",
      type: "input",
      value: "",
      label: "Piso",
      pattern: patternAlfanumerico,
      isInvalid: false,
      required: false,
    },
    dpto: {
      name: "dpto",
      type: "input",
      value: "",
      label: "Dpto",
      pattern: patternAlfanumerico,
      isInvalid: false,
      required: false,
    },
    cp: {
      name: "cp",
      type: "input",
      value: "",
      label: "Código Postal",
      pattern: patternNumerico,
      isInvalid: false,
      required: true,
      minLength: 4,
    },
    provincia: {
      name: "provincia",
      type: "input",
      value: "",
      label: "Provincia",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
      disabled: true,
    },
    formaPago: {
      name: "formaPago",
      type: "input",
      value: "",
      label: "Forma De Pago",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    tipoTarjeta: {
      name: "tipoTarjeta",
      type: "input",
      value: "",
      label: "Tarjeta",
      pattern: sinPattern,
      isInvalid: false,
      required: true,
    },
    numeroTarjeta: {
      name: "numeroTarjeta",
      type: "input",
      value: "",
      label: "Número Tarjeta",
      pattern: patternNumerico,
      isInvalid: false,
      required: true,
    },
    numeroCBU: {
      name: "numeroCBU",
      type: "input",
      value: "",
      label: "Número CBU",
      pattern: patternNumerico,
      isInvalid: false,
      required: true,
      minLength: 22,
    },
    titular: {
      name: "titular",
      type: "input",
      value: "",
      label: "Titular",
      pattern: patternAlfanumericoWithSpaces,
      isInvalid: false,
      required: true,
    },
    localidadSelectize: {
      name: "localidadSelectize",
      type: "input",
      value: "",
      label: "Localidad",
      pattern: patternAlfanumericoWithSpaces,
      isInvalid: false,
      required: true,
      disabled: true,
    },
    //esNuevo: {name: 'esNuevo', type: 'checkbox', value : false, label:'Es Nuevo', pattern: sinPattern, isInvalid: false}
  };

  const [component, setComponent] = useState(initialState);

  //props.checkToken();

  const [provincias, setProvincias] = useState([]);
  const [razas, setRazas] = useState([]);
  //const [searchRazas, setSearchRazas] = useState(false);
  const [localidades, setLocalidades] = useState([]);
  const [listaLocalidades, setListaLocalidades] = useState([]);
  const [productosPlanes, setProductosPlanes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [planes, setPlanes] = useState([]);
  const [emitiendo, setEmitiendo] = useState(false);
  const [productoPlanSeleccionado, setProductoPlanSeleccionado] = useState({
    information: undefined,
  });

  const validatePattern = useCallback(
    (componentName, componentValue) => {
      if (
        component[componentName].pattern === "" ||
        (component[componentName].required === false &&
          component[componentName].value === "")
      )
        return false;
      return !component[componentName].pattern.test(componentValue);
    },
    [component]
  );

  const validateRequiredError = useCallback(
    (targetName, targetValue) => {
      if (
        component[targetName].required === true &&
        (targetValue === "" || targetValue === null)
      ) {
        return true;
      }
      return false;
    },
    [component]
  );

  const validateMinLength = useCallback(
    (targetName, targetValue) => {
      return (
        targetValue && component[targetName].minLength > targetValue.length
      );
    },
    [component]
  );

  const validateComponent = useCallback(
    (targetName, targetValue) => {
      let required = validateRequiredError(targetName, targetValue);
      let isInvalidPattern = validatePattern(targetName, targetValue);
      let isInvalidMinLength = validateMinLength(targetName, targetValue);

      let message = undefined;

      if (required === true)
        message = `El campo ${component[targetName].label} es requerido`;
      if (isInvalidPattern === true)
        message = `El valor ${targetValue} es incorrecto`;
      if (isInvalidMinLength === true)
        message = `La cantidad de dígitos de ${targetValue} no es la correspondiente`;

      return message;
    },
    [component, validateMinLength, validatePattern, validateRequiredError]
  );

  const handleChange = useCallback(
    (event, callbackPre, callbackPost) => {
      if (event === undefined) {
        return;
      }

      let targetName = event.target.name;
      let targetValue = event.target.value;
      if (typeof targetValue === "string") {
        targetValue = targetValue.toUpperCase();
      }

      var newComponent = component;

      if (targetName === "dni") {
        targetValue = targetValue.replace(/[.]/g, "");
      }
      if (targetName === "precioCelular") {
        targetValue = targetValue.replace(/[,]/g, ".");
      }

      if (typeof callbackPre === "function")
        callbackPre(targetValue, targetName);

      newComponent[targetName].value = targetValue;
      newComponent[targetName].error = validateComponent(
        targetName,
        targetValue
      );
      setComponent((prevState) => ({
        ...prevState,
        [targetName]: newComponent[targetName],
      }));
      if (typeof callbackPost === "function")
        callbackPost(targetValue, targetName);
    },
    [component, validateComponent]
  );

  const filtroProducto = useCallback(() => {
    if (
      component.producto.value === "" ||
      component.plan.value === "" ||
      component.formaPago.value === ""
    )
      return;
    let productoSeleccionado = productosPlanes.find((elem) => {
      return (
        elem.planId === component.plan.value &&
        elem.productId === component.producto.value &&
        elem.mpId.toString() === component.formaPago.value
      );
    });

    if (productoSeleccionado) {
      setProductoPlanSeleccionado(productoSeleccionado);
    }
  }, [
    component.formaPago.value,
    component.plan.value,
    component.producto.value,
    productosPlanes,
  ]);

  const parsePlanes = useCallback(
    (productoId) => {
      setPlanes([]);
      let newComponent = component;
      component.plan.disabled = true;
      setComponent((prevState) => ({
        ...prevState,
        [component.plan.name]: newComponent[component.plan.name],
      }));
      let arrayPlanes = [];

      let planesFiltrados = _.filter(productosPlanes, (elem) => {
        if (elem.productId === productoId) return elem;
      });

      planesFiltrados = _.uniqBy(planesFiltrados, "planId");

      if (planesFiltrados) {
        planesFiltrados.forEach((element) => {
          arrayPlanes.push({
            id: element.planId,
            value: element.planId,
            text: element.planDescription.toUpperCase(),
          });
        });
      }

      setPlanes(arrayPlanes);
      newComponent = component;
      component.plan.disabled = false;
      setComponent((prevState) => ({
        ...prevState,
        [component.plan.name]: newComponent[component.plan.name],
      }));
      filtroProducto();
    },
    [component, filtroProducto, productosPlanes]
  );

  const redirectToLogin = useCallback(() => {
    props.history.push(URLMAPPING.LOGIN);
  }, [props.history]);

  const parseProvincias = useCallback(
    (provinciasProps) => {
      let provincias = [];
      if (provinciasProps) {
        provinciasProps.forEach((element) => {
            provincias.push({
              id: element.id,
              value: element.id,
              text: element.descripcion.toUpperCase(),
            });
        });
        component.provincia.disabled = false;
        component.localidadSelectize.disabled = true;
        setProvincias(provincias);
        if (provinciasProps.length === 1) {
          component.provincia.disabled = true;
          component.provincia.value = provinciasProps[0].id;
          changeLocalidad(provinciasProps[0].id);
        }
      }
    },
    [provincias]
  );

  const parseLocalidades = (localidades, provincias) => {
    setListaLocalidades(localidades);
    if(provincias.length === 1)
      changeLocalidad(provincias[0].id);
  }

  const parseRazas = useCallback(
    (razasProps) => {
      if (razasProps) {
        razasProps.forEach((element) => {
          razas.push({
            key: element.id,
            value: element.id,
            text: element.name.toUpperCase(),
          });
        });
        razas.push({ id: 9999, value: 9999, text: "OTRA" });
      }
    },
    [razas]
  );

  const [productosInit, setProductosInit] = useState(false);
  const [planesInit, setPlanesInit] = useState(false);
  const [razasInit, setRazasInit] = useState(false);

  const parseProductos = useCallback(() => {
    setProductosPlanes(props.productos);
    let array = [];

    let productosFiltrados = _.uniqBy(props.productos, "productId");
    if (productosFiltrados) {
      productosFiltrados.forEach((element) => {
        array.push({
          id: element.productId,
          value: element.productId,
          text: element.title.toUpperCase(),
        });
      });
    }
    setProductos(array);
  }, [props.productos]);

  const seleccionarPlan = useCallback(() => {
    if (planes && planes.length === 1) {
      handleChange(
        { target: { name: component.plan.name, value: planes[0].value } },
        undefined,
        filtroProducto
      );
    }
  }, [component.plan.name, filtroProducto, handleChange, planes]);

  const seleccionarProducto = useCallback(() => {
    if (productos && productos.length === 1)
      handleChange(
        {
          target: { name: component.producto.name, value: productos[0].value },
        },
        undefined,
        parsePlanes
      );
  }, [component.producto.name, handleChange, parsePlanes, productos]);

  useEffect(() => {
    if (props.status === CONSTANTS.NOT_LOGGED) {
      redirectToLogin();
    }
    if (
      provincias[0] === undefined &&
      props.provincias !== undefined &&
      props.codigoPostalStatus === CONSTANTS.NEW_FORM_CODIGO_POSTAL_SUCCESS
    ) {
      parseProvincias(props.provincias);
    }
    if (
      localidades[0] === undefined &&
      props.localidades !== undefined &&
      props.codigoPostalStatus === CONSTANTS.NEW_FORM_CODIGO_POSTAL_SUCCESS
    ) {
      parseLocalidades(props.localidades, props.provincias);
    }

    if (
      razas[0] === undefined &&
      props.razas !== undefined &&
      props.razasStatus !== CONSTANTS.NEW_FORM_RAZAS_LOADING
    ) {
      parseRazas(props.razas);
    }

    if (
      props.productos === undefined &&
      props.productosStatus !== CONSTANTS.NEW_FORM_PRODUCTOS_LOADING &&
      props.productosStatus !== CONSTANTS.NEW_FORM_PRODUCTOS_ERROR
    ) {
      props.getProductos();
    }

    if (productos[0] === undefined && props.productos !== undefined) {
      parseProductos(props.productos);
    }

    if (
      productosInit === false &&
      productos[0] !== undefined &&
      productos.length === 1
    ) {
      seleccionarProducto();
      setProductosInit(true);
    }

    if (planesInit === false && planes[0] !== undefined) {
      seleccionarPlan();
      setPlanesInit(true);
    }

    if (razasInit === false && razas[0] === undefined) {
      props.getRazas(component.petType.value);
      setRazasInit(true);
    }

    if (emitiendo && props.emisionStatus === CONSTANTS.NEW_FORM_EMITIR_ERROR) {
      notificationService(
        "Hubo un error inesperado intente en unos minutos y vuelva a intentar de mantenerse el error consulte con el Administrador."
      );
      setEmitiendo(false);
    }

    if (
      props.emision !== undefined &&
      props.emisionStatus !== CONSTANTS.NEW_FORM_EMITIR_LOADING
    ) {
      setEmitiendo(false);
      props.history.push(URLMAPPING.SUCCESS);
    }
  }, [
    props,
    provincias,
    productos,
    localidades,
    razas,
    redirectToLogin,
    parseProvincias,
    parseProductos,
    emitiendo,
    component.petType.value,
    parseRazas,
    planes,
    planesInit,
    productosInit,
    razasInit,
    seleccionarPlan,
    seleccionarProducto,
  ]);

  const changeRequiredCreditCard = () => {
    let newComponent = component;

    /** TARJETA DE CREDITO **/
    if (newComponent.formaPago.value === "3") {
      newComponent.numeroCBU.value = "";
      newComponent.numeroCBU.error = undefined;
      newComponent.numeroCBU.required = false;
      newComponent.numeroCBU.isInvalid = false;
      newComponent.tipoTarjeta.required = true;
      newComponent.numeroTarjeta.required = true;
    }

    /** CBU **/
    if (newComponent.formaPago.value === "6") {
      newComponent.tipoTarjeta.value = "";
      newComponent.numeroTarjeta.value = "";
      newComponent.tipoTarjeta.error = undefined;
      newComponent.numeroTarjeta.error = undefined;
      newComponent.tipoTarjeta.isInvalid = false;
      newComponent.tipoTarjeta.required = false;
      newComponent.numeroTarjeta.isInvalid = false;
      newComponent.numeroTarjeta.required = false;
      newComponent.numeroCBU.required = true;
    }

    setComponent((prevState) => ({
      ...prevState,
      [newComponent.numeroCBU.name]: newComponent.numeroCBU,
    }));
    setComponent((prevState) => ({
      ...prevState,
      [newComponent.tipoTarjeta.name]: newComponent.tipoTarjeta,
    }));
    setComponent((prevState) => ({
      ...prevState,
      [newComponent.numeroTarjeta.name]: newComponent.numeroTarjeta,
    }));
  };

  const iterateComponentsForEmptyRequireds = () => {
    let requiredError = false;
    var newComponent = component;
    Object.keys(newComponent).forEach((targetName) => {
      if (
        newComponent[targetName].required === true &&
        newComponent[targetName].value === ""
      ) {
        newComponent[
          targetName
        ].error = `El campo ${newComponent[targetName].label} es requerido`;
        setComponent((prevState) => ({
          ...prevState,
          [targetName]: newComponent[targetName],
        }));
        requiredError = true;
      }
    });
    return requiredError;
  };

  const iterateComponentsForInvalids = () => {
    let isInvalid = false;
    var newComponent = component;
    Object.keys(newComponent).forEach((targetName) => {
      if (newComponent[targetName].error !== undefined) {
        newComponent[
          targetName
        ].error = `El valor ${newComponent[targetName].value} es incorrecto`;
        setComponent((prevState) => ({
          ...prevState,
          [targetName]: newComponent[targetName],
        }));
        isInvalid = true;
      }
    });
    return isInvalid;
  };

  const isFormValid = () => {
    if (iterateComponentsForEmptyRequireds()) {
      notificationService("Hay campos requeridos que no estan cargados.");
      return false;
    }

    if (iterateComponentsForInvalids()) {
      notificationService(
        "Hay errores en el formulario por favor verifiquelo y vuelva a intentarlo."
      );
      return false;
    }

    return true;
  };

  const createPayload = () => {
    return {
      documentType: component.tipoIdentificacion.value,
      document: component.dni.value,
      name: component.nombre.value,
      surname: component.apellido.value,
      dateOfBirth: formatDate(component.fechaNacimiento.value),
      gender: component.sexo.value,
      email: component.email.value,
      telephonePrefix: component.area.value,
      telephone: component.telefono.value,
      addressStreet: component.calle.value,
      addressNumber: component.calleNumero.value,
      addressFloor: component.piso.value,
      addressDepartament: component.dpto.value,
      provinceCod: component.provincia.value,
      provinceDescription: props.provincias.find(
        (provincias) => provincias.id === component.provincia.value
      ).descripcion,
      cityCod: props.localidades.find(
        (localidades) => localidades.descripcion.toUpperCase() ===
        component.localidadSelectize.value
      ).id,
      cityDescription: component.localidadSelectize.value,
      postalCod: component.cp.value,
      entityId: productoPlanSeleccionado.entityClientId,
      soldProductAttributes: [
        {
          attributeKey: "tipoMascota",
          attributeValue: component.petType.value,
          attributeLabel: "Tipo de Mascota",
          attributeFormatted: component.petType.value,
        },
        {
          attributeKey: "nombreMascota",
          attributeValue: component.nombreMascota.value,
          attributeLabel: "Nombre de la Mascota",
          attributeFormatted: component.nombreMascota.value,
        },
        {
          attributeKey: "sexoMascota",
          attributeValue: component.sexoMascota.value,
          attributeLabel: "Sexo",
          attributeFormatted: component.sexoMascota.value,
        },
        {
          attributeKey: "razaMascota",
          attributeValue:
            unlockRazaCustomField === true
              ? component.razaCustom.value
              : findRazaByValue(component.raza.value),
          attributeLabel: "Raza",
          attributeFormatted:
            unlockRazaCustomField === true
              ? component.razaCustom.value
              : findRazaByValue(component.raza.value),
        },
        {
          attributeKey: "fechaNacimientoMascota",
          attributeValue: component.fechaNacimientoMascota.value,
          attributeLabel: "Nacimiento",
          attributeFormatted: formatDatePets(
            component.fechaNacimientoMascota.value
          ),
        },
        {
          attributeKey: "vacunasAlDiaMascota",
          attributeValue: component.vacunasAlDia.value,
          attributeLabel: "Tiene las vacunas al día",
          attributeFormatted: component.vacunasAlDia.value,
        },
        {
          attributeKey: "esSeguridadMascota",
          attributeValue: component.mascotaEsDeSeguridad.value,
          attributeLabel: "La mascota es de seguridad",
          attributeFormatted: component.mascotaEsDeSeguridad.value,
        },
      ],
      productId: productoPlanSeleccionado.productId,
      planId: productoPlanSeleccionado.planId,
      productTitle: productoPlanSeleccionado.title,
      planDescription: productoPlanSeleccionado.planDescription,
      mpId: component.formaPago.value,
      billingDuration: productoPlanSeleccionado.billingDuration,
      typeCard: component.tipoTarjeta.value,
      cardNumber: component.numeroTarjeta.value,
      cbuNumber: component.numeroCBU.value,
      titular: component.titular.value,
      totalPrice: productoPlanSeleccionado.price,
    };
  };

  const [formIsInvalid, setFormIsInvalid] = useState(false);

  const findRazaByValue = () => {
    return _.find(razas, (item) => {
      return item.value === component.raza.value;
    }).text;
  };

  const onClickEmitir = () => {
    setEmitiendo(true);
    console.log("Emitir: ", component);
    if (isFormValid()) {
      setFormIsInvalid(false);
      props.emitir(createPayload());
      return;
    }

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    setFormIsInvalid(true);
    setEmitiendo(false);
  };

  const onClickCancelar = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.location.reload();
  };

  const formatDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return year + "/" + month + "/" + day;
  };

  const formatDatePets = (date) => {
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    let year = date.getFullYear();
    return day + "/" + month + "/" + year;
  };

  const [openModal, setOpenModal] = useState();
  const [formaPago, setFormaPago] = useState();

  const [notificacionError, setNotificacionError] = useState(false);

  const changeCodigoPostal = (value) => {
    setLocalidades([]);
    setProvincias([]);
    component.localidadSelectize.value = "";
    component.localidadSelectize.disabled = true;
    component.provincia.disabled = true;
    component.provincia.value = "";
    if (value.length > 3) {
      let newComponent = component;
      props.searchCodigoPostal(value);
      setComponent((prevState) => ({
        ...prevState,
        [component.localidadSelectize.name]:
          newComponent[component.localidadSelectize.name],
        [component.provincia.name]: newComponent[component.provincia.name],
      }));
    }
    props.resetGeo();
  };

  const changeLocalidad = (value) => {
    setLocalidades([]);
    let localidades = [];
    if(listaLocalidades)
      listaLocalidades.forEach(loc => {
        if(loc.provincia === value)
          localidades.push({
            id: loc.id,
            value: loc.id,
            text: loc.descripcion.toUpperCase(),
          });
      });
    component.localidadSelectize.disabled = false;
    component.localidadSelectize.value = "";
    setLocalidades(localidades);
    if(localidades.length === 1){
      component.localidadSelectize.disabled = true;
      component.localidadSelectize.error = undefined;
      component.localidadSelectize.value =
      localidades[0].text;
    }
  };
  const showFormaPagoAndFiltroProductoPlan = (value) => {
    setFormaPago(value);
    changeRequiredCreditCard();
    filtroProducto();
  };

  const verCoberturas = () => {
    if (productoPlanSeleccionado.information !== undefined) {
      setOpenModal(true);
      setTimeout(() => {
        setOpenModal(false);
      }, 100);
    }
  };

  const [mensajeErrorNotification, setMensajeErrorNotification] = useState("");

  const notificationService = (errorMessage) => {
    setMensajeErrorNotification(errorMessage);
    setNotificacionError(true);
    setTimeout(() => {
      setNotificacionError(false);
    }, 1000);
  };

  const changeMinLengthTarjeta = (targetValue, targetName) => {
    let newComponent = component;
    component.numeroTarjeta.minLength = targetValue === "19" ? 15 : 16;
    component.numeroTarjeta.value = targetValue = "";
    setComponent((prevState) => ({
      ...prevState,
      [component.numeroTarjeta.name]: newComponent.numeroTarjeta,
    }));
    setComponent((prevState) => ({
      ...prevState,
      [component.numeroTarjeta.name]: newComponent.numeroTarjeta,
    }));
  };

  const validateLocalidad = (targetValue, targetName) => {
    let element = localidades.find(
      (elem) => elem.text.toUpperCase() === targetValue.toUpperCase()
    );
    if (element === undefined) {
      component[
        targetName
      ].error = `La localidad ${targetValue} ingresada no existe`;
      let newComponent = component;
      setComponent((prevState) => ({
        ...prevState,
        [targetName]: newComponent[targetName],
      }));
    }
  };

  const calculateMaxDate = () => {
    let today = new Date();
    let date14yearsAgo = new Date(
      today.getFullYear() - 14,
      today.getMonth(),
      today.getDate()
    );
    return date14yearsAgo;
  };

  const resetPetType = () => {
    setRazas([]);
    unlockRazaCustom();
    props.getRazas(component.petType.value);
  };

  const [unlockRazaCustomField, setUnlockRazaCustomField] = useState(false);

  const unlockRazaCustom = (value) => {
    //SELECCIONA OTRA
    let newComponent = component;
    if (value === 9999) {
      setUnlockRazaCustomField(true);
      newComponent["razaCustom"].required = true;
      return;
    } else {
      setUnlockRazaCustomField(false);
      newComponent["razaCustom"].value = "";
      newComponent["razaCustom"].required = false;
      newComponent["razaCustom"].error = undefined;
    }
    setComponent((prevState) => ({
      ...prevState,
      [component.razaCustom.name]: newComponent[component.razaCustom.name],
    }));
  };

  const calculateMaxDateMascota = () => {
    let today = new Date();
    let date14yearsAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 3,
      today.getDate()
    );
    return date14yearsAgo;
  };

  const calculateMinDateMascota = () => {
    let today = new Date();
    let date14yearsAgo = new Date(
      today.getFullYear() - 10,
      today.getMonth(),
      today.getDate()
    );
    return date14yearsAgo;
  };

  const validateVacunasAlDia = (value) => {
    let newComponent = component;
    newComponent["vacunasAlDia"].error =
      value === "NO" ? "La mascota debe tener las vacunas al día" : undefined;
    setComponent((prevState) => ({
      ...prevState,
      [component.vacunasAlDia.name]: newComponent[component.vacunasAlDia.name],
    }));
  };

  const esSeguridad = (value) => {
    let newComponent = component;
    newComponent["mascotaEsDeSeguridad"].error =
      value === "SI"
        ? "No esta permitida la venta para mascotas de seguridad y/o Vigilancia"
        : undefined;
    setComponent((prevState) => ({
      ...prevState,
      [component.mascotaEsDeSeguridad.name]:
        newComponent[component.mascotaEsDeSeguridad.name],
    }));
  };

  const [maxDate] = useState(calculateMaxDate);
  const [minDateMascota] = useState(calculateMinDateMascota);
  const [maxDateMascota] = useState(calculateMaxDateMascota);

  return (
    <div>
      <Message
        className={formIsInvalid ? "show" : "not-show"}
        error
        content={"* " + mensajeErrorNotification}
      />

      <NotificationServiceCnt
        open={notificacionError}
        titulo={"Ha ocurrido un error"}
        texto={mensajeErrorNotification}
        tipo={"error"}
      />

      <ModalCoberturas
        show={openModal}
        productoPlan={productoPlanSeleccionado}
      />

      <React.Fragment>
        <Form className="form-large" autoComplete="off">
          <div className={"section-title"}>Datos del Producto</div>
          <Form.Group widths="equal">
            <Form.Field
              control={Select}
              options={productos}
              placeholder={component.producto.label}
              name={component.producto.name}
              value={component.producto.value}
              label={component.producto.label}
              disabled={
                productos.length === 1 ? true : component.producto.disabled
              }
              onChange={(e, o) =>
                handleChange(
                  { target: { name: component.producto.name, value: o.value } },
                  undefined,
                  parsePlanes
                )
              }
              error={component.producto.error}
            />
            <Form.Field
              control={Select}
              options={planes}
              placeholder={component.plan.label}
              name={component.plan.name}
              label={component.plan.label}
              value={component.plan.value}
              disabled={planes.length === 1 ? true : component.plan.disabled}
              onChange={(e, o) =>
                handleChange(
                  { target: { name: component.plan.name, value: o.value } },
                  undefined,
                  filtroProducto
                )
              }
              error={component.plan.error}
            />
            <Form.Field
              control={Select}
              options={formasPagoOptions}
              placeholder={component.formaPago.label}
              name={component.formaPago.name}
              label={component.formaPago.label}
              error={component.formaPago.error}
              onChange={(e, o) =>
                handleChange(
                  {
                    target: { name: component.formaPago.name, value: o.value },
                  },
                  undefined,
                  showFormaPagoAndFiltroProductoPlan
                )
              }
            />
            <Button
              size="small"
              fluid
              className={"boton-ver-coberturas"}
              onClick={() => verCoberturas()}
              type="button"
            >
              Ver Coberturas
            </Button>
          </Form.Group>
          <Form.Group widths="equal">
            <div className="field">
              {productoPlanSeleccionado && productoPlanSeleccionado.price ? (
                <Label className="label-price">
                  Costo Mensual: ${productoPlanSeleccionado.price}
                </Label>
              ) : null}
            </div>
          </Form.Group>
          <div className={"section-title"}>Datos de la Mascota</div>

          <Form.Group widths="equal">
            <OptionSelectCmp
              options={petTypeOptions}
              name={component.petType.name}
              label={component.petType.label}
              onChange={handleChange}
              callbackPost={resetPetType}
              value={component.petType.value}
              error={component.petType.error}
              required={component.petType.required}
            />
            <Form.Field
              control={Input}
              name={component.nombreMascota.name}
              value={component.nombreMascota.value}
              label={component.nombreMascota.label}
              placeholder={component.nombreMascota.label}
              onChange={handleChange}
              error={component.nombreMascota.error}
              maxLength="100"
            />
            <OptionSelectCmp
              options={sexoMascotaOptions}
              name={component.sexoMascota.name}
              label={component.sexoMascota.label}
              onChange={handleChange}
              value={component.sexoMascota.value}
              error={component.sexoMascota.error}
            />
            <Form.Field
              control={Select}
              options={razas}
              placeholder={component.raza.label}
              name={component.raza.name}
              label={component.raza.label}
              error={component.raza.error}
              onChange={(e, o) =>
                handleChange(
                  { target: { name: component.raza.name, value: o.value } },
                  undefined,
                  unlockRazaCustom
                )
              }
            />

            <Form.Field
              control={Input}
              name={component.razaCustom.name}
              value={component.razaCustom.value}
              label={component.razaCustom.label}
              placeholder={component.razaCustom.placeholder}
              onChange={handleChange}
              error={component.razaCustom.error}
              disabled={!unlockRazaCustomField}
              maxLength="50"
            />
          </Form.Group>
          <Form.Group>
            <DatePickerCmp
              error={component.fechaNacimientoMascota.error}
              label={component.fechaNacimientoMascota.label}
              selected={component.fechaNacimientoMascota.value}
              placeholderText={component.fechaNacimientoMascota.placeholder}
              name={component.fechaNacimientoMascota.name}
              onChange={handleChange}
              minDate={minDateMascota}
              maxDate={maxDateMascota}
            />
            <OptionSelectCmp
              options={vacunasAlDiaOptions}
              name={component.vacunasAlDia.name}
              label={component.vacunasAlDia.label}
              value={component.vacunasAlDia.value}
              onChange={handleChange}
              error={component.vacunasAlDia.error}
              callbackPost={validateVacunasAlDia}
            />
            <OptionSelectCmp
              options={vacunasAlDiaOptions}
              name={component.mascotaEsDeSeguridad.name}
              label={component.mascotaEsDeSeguridad.label}
              value={component.mascotaEsDeSeguridad.value}
              onChange={handleChange}
              error={component.mascotaEsDeSeguridad.error}
              callbackPost={esSeguridad}
            />
          </Form.Group>
          <div className={"section-title"}>Datos del Cliente</div>
          <Form.Group widths="equal">
            <Form.Field
              control={Select}
              options={tipoIdentificacionOptions}
              placeholder={component.tipoIdentificacion.label}
              value={
                tipoIdentificacionOptions.length === 1
                  ? tipoIdentificacionOptions[0].value
                  : component.tipoIdentificacion.value
              }
              name={component.tipoIdentificacion.name}
              label={component.tipoIdentificacion.label}
              disabled={
                tipoIdentificacionOptions.length === 1
                  ? true
                  : component.tipoIdentificacion.disabled
              }
              onChange={(e, o) =>
                handleChange({
                  target: {
                    name: component.tipoIdentificacion.name,
                    value: o.value,
                  },
                })
              }
              error={component.tipoIdentificacion.error}
            />

            <Form.Field
              control={Input}
              type="tel"
              name={component.dni.name}
              label={component.dni.label}
              value={component.dni.value}
              placeholder={component.dni.label}
              onChange={handleChange}
              error={component.dni.error}
              maxLength="11"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              name={component.nombre.name}
              value={component.nombre.value}
              label={component.nombre.label}
              placeholder={component.nombre.label}
              onChange={handleChange}
              error={component.nombre.error}
              maxLength="100"
            />

            <Form.Field
              control={Input}
              value={component.apellido.value}
              name={component.apellido.name}
              label={component.apellido.label}
              placeholder={component.apellido.label}
              onChange={handleChange}
              error={component.apellido.error}
              maxLength="100"
            />
            <DatePickerCmp
              error={component.fechaNacimiento.error}
              label={component.fechaNacimiento.label}
              selected={component.fechaNacimiento.value}
              placeholderText={component.fechaNacimiento.label}
              name={component.fechaNacimiento.name}
              onChange={handleChange}
              maxDate={maxDate}
            />
            <Form.Field
              control={Select}
              options={sexoOptions}
              placeholder={component.sexo.label}
              name={component.sexo.name}
              label={component.sexo.label}
              onChange={(e, o) =>
                handleChange({
                  target: { name: component.sexo.name, value: o.value },
                })
              }
              error={component.sexo.error}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              value={component.email.value}
              name={component.email.name}
              label={component.email.label}
              placeholder={component.email.label}
              onChange={handleChange}
              error={component.email.error}
              maxLength="100"
            />
            <Form.Field
              control={Input}
              value={component.area.value}
              name={component.area.name}
              label={component.area.label}
              placeholder={component.area.label}
              onChange={handleChange}
              error={component.area.error}
              maxLength="3"
            />
            <Form.Field
              control={Input}
              value={component.telefono.value}
              name={component.telefono.name}
              label={component.telefono.label}
              placeholder={component.telefono.label}
              onChange={handleChange}
              error={component.telefono.error}
              maxLength="13"
              autoComplete="new-password"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              value={component.calle.value}
              name={component.calle.name}
              label={component.calle.label}
              placeholder={component.calle.label}
              onChange={handleChange}
              error={component.calle.error}
              autoComplete="new-password"
              maxLength="100"
            />
            <Form.Field
              control={Input}
              value={component.calleNumero.value}
              name={component.calleNumero.name}
              label={component.calleNumero.label}
              placeholder={component.calleNumero.label}
              onChange={handleChange}
              error={component.calleNumero.error}
              maxLength="5"
            />
            <Form.Field
              control={Input}
              value={component.piso.value}
              name={component.piso.name}
              label={component.piso.label}
              placeholder={component.piso.label}
              onChange={handleChange}
              error={component.piso.error}
              maxLength="2"
            />
            <Form.Field
              control={Input}
              value={component.dpto.value}
              name={component.dpto.name}
              label={component.dpto.label}
              placeholder={component.dpto.label}
              onChange={handleChange}
              error={component.dpto.error}
              maxLength="2"
              autoComplete="new-password"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field
              control={Input}
              value={component.cp.value}
              name={component.cp.name}
              label={component.cp.label}
              placeholder={component.cp.label}
              onChange={(e, o) =>
                handleChange(
                  { target: { name: component.cp.name, value: o.value } },
                  undefined,
                  changeCodigoPostal
                )
              }
              error={component.cp.error}
              maxLength="4"
              autoComplete="new-password"
            />
            <Form.Field
              control={Select}
              options={provincias}
              value={component.provincia.value}
              placeholder={component.provincia.label}
              name={component.provincia.name}
              label={component.provincia.label}
              error={component.provincia.error}
              disabled={component.provincia.disabled}
              onChange={(e, o) =>
                handleChange(
                  {
                    target: { name: component.provincia.name, value: o.value },
                  },
                  undefined,
                  changeLocalidad
                )
              }
            />
            <SelectizeCmp
              localidades={localidades}
              value={component.localidadSelectize.value}
              name={component.localidadSelectize.name}
              label={component.localidadSelectize.label}
              placeholder={component.localidadSelectize.label}
              onChange={handleChange}
              disabled={component.localidadSelectize.disabled}
              error={component.localidadSelectize.error}
              validateLocalidad={validateLocalidad}
              maxLength="100"
            />
          </Form.Group>
          {formaPago !== undefined ? (
            <div className={"section-title"}>Forma de Pago</div>
          ) : null}
          <Form.Group widths="equal">
            {formaPago === "3" ? (
              <Form.Field
                control={Select}
                options={tarjetasOptions}
                placeholder={component.tipoTarjeta.label}
                name={component.tipoTarjeta.name}
                label={component.tipoTarjeta.label}
                onChange={(e, o) =>
                  handleChange(
                    {
                      target: {
                        name: component.tipoTarjeta.name,
                        value: o.value,
                      },
                    },
                    changeMinLengthTarjeta
                  )
                }
                error={component.tipoTarjeta.error}
              />
            ) : null}

            {formaPago === "3" ? (
              <Form.Field
                control={Input}
                value={component.numeroTarjeta.value}
                name={component.numeroTarjeta.name}
                label={component.numeroTarjeta.label}
                placeholder={component.numeroTarjeta.label}
                onChange={handleChange}
                error={component.numeroTarjeta.error}
                maxLength={component.tipoTarjeta.value === "19" ? "15" : "16"}
              />
            ) : null}

            {formaPago === "6" ? (
              <Form.Field
                control={Input}
                value={component.numeroCBU.value}
                name={component.numeroCBU.name}
                label={component.numeroCBU.label}
                placeholder={component.numeroCBU.label}
                onChange={handleChange}
                error={component.numeroCBU.error}
                maxLength="22"
                autoComplete="new-password"
              />
            ) : null}
            {formaPago !== undefined ? (
              <Form.Field
                control={Input}
                value={component.titular.value}
                name={component.titular.name}
                label={component.titular.label}
                placeholder={component.titular.label}
                onChange={handleChange}
                error={component.titular.error}
                maxLength="100"
                autoComplete="new-password"
              />
            ) : null}
          </Form.Group>

          <Form.Group widths="equal">
            {/*<div className="field">
                <label>Es Nuevo</label>
                <span className={"switch"}> 
                  <span className={`slider ${component.esNuevo.value === true ? "checked-custom" : ""}`  } 
                  onClick={(e)=>handleChange({target:{name :component.esNuevo.name,value: !component.esNuevo.value}})}> 
                  </span>
                </span>
              </div>*/}
          </Form.Group>

          <Form.Group widths="equal"></Form.Group>

          <div className={"buttons-submit-right"}>
            <Button
              color="grey"
              className={"button-submit"}
              size="large"
              onClick={() => onClickCancelar()}
              type="button"
            >
              Cancelar
            </Button>
            <Button
              className={"button-submit " + (emitiendo ? "loading" : "")}
              size="large"
              onClick={() => onClickEmitir()}
              type="button"
              disabled={emitiendo}
            >
              Emitir
            </Button>
          </div>
        </Form>
      </React.Fragment>

      <SpinnerWithDimmerCmp
        active={emitiendo}
        title={"Espere unos instantes mientras se genera el comprobante ..."}
      />
      {<Footer active={true} />}
    </div>
  );
};
export default NewFormCmp;
