import * as CONSTANTS from '../constants/constants'

export function searchCodigoPostal (payload) {
    return {
        type: CONSTANTS.NEW_FORM_CODIGO_POSTAL_LOADING,
        payload
    }
}

export function resetGeo () {
    return {
        type: CONSTANTS.NEW_FORM_CODIGO_POSTAL_RESET
    }
}

export function restart () {
    return {
        type: CONSTANTS.RESET_NOTIFICATION_SERVICE_LOADING,
        payload : {open: false}
    }
}

export function emitir(payload) {
    return {
        type: CONSTANTS.NEW_FORM_EMITIR_LOADING,
        payload: payload
    }
}

export function getEmisiones(payload) {
    return {
        type: CONSTANTS.NEW_FORM_GETEMISIONES_LOADING,
        payload: payload
    }
}

export function downloadPDF(payload) {
    return {
        type: CONSTANTS.NEW_FORM_DOWNLOADPDF_LOADING,
        payload: payload
    }
}

export function getProductos() {
    return {
        type: CONSTANTS.NEW_FORM_PRODUCTOS_LOADING
    }
}

export function createUser(payload) {
    return {
        type: CONSTANTS.NEW_FORM_CREATE_USER_LOADING,
        payload: payload
    }
}

export function usernameExist(payload) {
    return {
        type: CONSTANTS.NEW_FORM_USER_EXIST_LOADING,
        payload: payload
    }
}

export function findUsers(payload) {
    return {
        type: CONSTANTS.NEW_FORM_FIND_USERS_LOADING,
        payload: payload
    }
}

export function updateUser(payload) {
    return {
        type: CONSTANTS.NEW_FORM_UPDATE_USER_LOADING,
        payload: payload
    }
}
export function downloadCSV(payload) {
    return {
        type: CONSTANTS.NEW_FORM_DOWNLOAD_CSV_LOADING,
        payload: payload
    }
}

export function getEntidades() {
    return {
        type: CONSTANTS.NEW_FORM_ENTIDADES_LOADING
    }
}
export function getRazas(payload) {
    return {
        type: CONSTANTS.NEW_FORM_RAZAS_LOADING,
        payload: payload
    }
}
export function checkEmailValid(payload){
    return {
        type: CONSTANTS.NEW_FORM_CHECK_EMAIL_VALID_LOADING,
        payload: payload
    }
}

export const actions = {
    emitir,
    searchCodigoPostal,
    resetGeo,
    getEmisiones,
    downloadPDF,
    getProductos,
    createUser,
    usernameExist,
    findUsers,
    updateUser,
    downloadCSV,
    getEntidades,
    getRazas,
    checkEmailValid
};