import React, { useState } from 'react'
import { Dropdown, Menu } from 'semantic-ui-react'
import { withRouter } from 'react-router';
//import img from "../images/image.png"
import { Link } from 'react-router-dom';
import { API_URL_FRONT } from "../config/config";
import * as URLMAPPING from '../config/UrlMapping'

function MenuCmp(props) {

  let redirectMap = {
    'home': URLMAPPING.HOME,
    'list': URLMAPPING.LIST, 
    'create': URLMAPPING.CREATE, 
    'list-admin': URLMAPPING.LIST_ADMIN, 
    'create-user': URLMAPPING.CREATE_USER, 
    'cambiar-password-logged': URLMAPPING.CAMBIAR_PASSWORD_LOGGED, 
    'download-csv': URLMAPPING.DOWNLOAD_CSV
  }

  function redirect(redirectTo) {

    if(redirectTo === 'create'){
      window.location.href = `${API_URL_FRONT}${URLMAPPING.CREATE}`;
      return;
    }
    setOpenMenu(false)  
    setOpenSubMenuPrincipal(false)
    setOpenSubMenuLogout(false)

    //console.log(localStorage.getItem('account'))
    props.history.push(redirectMap[redirectTo])
  }

  function onClickLogout() {
    props.logout()
  }

  let username = JSON.parse(localStorage.getItem('account')).username.toUpperCase()
  let name = JSON.parse(localStorage.getItem('account')).name.toUpperCase()
  let lastname = JSON.parse(localStorage.getItem('account')).lastname.toUpperCase()
  let branchNumber = JSON.parse(localStorage.getItem('account')).branch_number.toUpperCase()
  let hasPermission = JSON.parse(localStorage.getItem('account')).authorizations.find(element => element === 'PERM_ADMIN')
  let hasManagerPermission = JSON.parse(localStorage.getItem('account')).authorizations.find(element => element === 'PERM_MANAGER')
  
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenuPrincipal, setOpenSubMenuPrincipal] = useState(false);
  const [openSubMenuLogout, setOpenSubMenuLogout] = useState(false);


  const openCloseMenu = () =>{
    setOpenMenu(!openMenu)
  }

  const openCloseMenuPrincipal = () =>{

    setOpenSubMenuPrincipal(!openSubMenuPrincipal)
  }

  const openCloseMenuLogout = () =>{
    setOpenSubMenuLogout(!openSubMenuLogout)
  }

  const getUrlLogo = () =>{
    if(window.location.href.search("ecolon") !== -1)
      return "ecolon-logo";
    if(window.location.href.search("colonasistencias") !== -1)
      return "colon-asistencias-logo";
    if(window.location.href.search("colonseguros") !== -1)
      return "colon-seguros-logo";
    return "ecolon-logo";
  }

  return (
    <React.Fragment>
      
      <div className={"menu-mobile" } >
        <div className={"menu-mobile-button "+ (openMenu ? 'selected' : '')} onClick={openCloseMenu}><i className="icon content"></i></div>
        <div className={"mobile-logo logo " + (getUrlLogo())} > <Link to={URLMAPPING.HOME}><div className="logo center-image" /></Link></div>
      </div>
      <div className={"menu-container-mobile " +(openMenu ? 'show' : 'not-show ')  }> 
        <div className={"main-row menu-principal-mobile " + (openSubMenuPrincipal ? ' selected' : '')} onClick={openCloseMenuPrincipal}>MENU</div>
          <div className={"menu-row item-menu-principal-mobile " + (openSubMenuPrincipal ? 'show' : 'not-show')} onClick={()=>redirect("home")}>INICIO</div>
          <div className={"menu-row item-menu-principal-mobile " + (openSubMenuPrincipal ? 'show' : 'not-show')} onClick={()=>redirect("list")}>CONSULTA</div>
          <div className={"menu-row item-menu-principal-mobile " + (openSubMenuPrincipal ? 'show' : 'not-show')} onClick={()=>redirect("create")}>NUEVA VENTA</div>
          <div className={"menu-row item-menu-principal-mobile " + (openSubMenuPrincipal && ( hasPermission || hasManagerPermission ) ? 'show' : 'not-show')} onClick={()=>redirect("create-user")}>NUEVO USUARIO-ADMIN</div>
          <div className={"menu-row item-menu-principal-mobile " + (openSubMenuPrincipal && hasPermission ? 'show' : 'not-show')} onClick={()=>redirect("list-admin")}>LISTADO-ADMIN</div>
        <div className={"main-row menu-logout-mobile " + (openSubMenuLogout ? 'selected' : '')} onClick={openCloseMenuLogout}>{"SUC:" + branchNumber + " " + lastname + ", " + name + " (" + username + ")"}</div>
          <div className={"menu-row item-menu-logout-mobile " + (openSubMenuLogout ? 'show' : 'not-show')} onClick={()=>redirect("cambiar-password-logged")}>Cambiar Contraseña</div>
          <div className={"menu-row item-menu-logout-mobile " + (openSubMenuLogout ? 'show' : 'not-show')} onClick={()=>onClickLogout()}> Cerrar Sesión</div>
      </div>
      
      
      <div className='menu-web'>
        <Menu attached='top'>
          <Link to={URLMAPPING.HOME}><div className={"logo " + (getUrlLogo())} ></div></Link>
          <Dropdown text={"MENU"} item className="menu-routes">
            <Dropdown.Menu>
              <Dropdown.Item onClick={()=>redirect("home")}>INICIO</Dropdown.Item>
              <Dropdown.Item onClick={()=>redirect("list")}>CONSULTA</Dropdown.Item>
              <Dropdown.Item onClick={()=>redirect("create")}>NUEVA VENTA</Dropdown.Item>
              <Dropdown.Item onClick={()=>redirect("create-user")} className={(hasPermission || hasManagerPermission) ? 'show' : 'not-show'}>NUEVO USUARIO ADMIN</Dropdown.Item>
              <Dropdown.Item onClick={()=>redirect("list-admin")} className={hasPermission ? 'show' : 'not-show'}>LISTADO USUARIOS ADMIN</Dropdown.Item>
              <Dropdown.Item onClick={()=>redirect("download-csv")} className={hasPermission ? 'show' : 'not-show'}>DESCARGA CSV ADMIN</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        
          <Dropdown text={ "SUC:" + branchNumber + " " + lastname + ", " + name + " (" + username + ")"} item className="logout-menu">
          <Dropdown.Menu>
            <Dropdown.Item onClick={()=>redirect("cambiar-password-logged")}>Cambiar Contraseña</Dropdown.Item>
            <Dropdown.Item onClick={()=>onClickLogout()}>Cerrar Sesión</Dropdown.Item>
          </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </div>
    </React.Fragment>
    )
}
export default withRouter(MenuCmp);