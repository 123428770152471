
import SuccessCmp from '../components/SuccessCmp.js';

const { connect } = require('react-redux');

const { logout, checkToken  } = require('../actions/loginAction')
const { downloadPDF } = require('../actions/homeAction')

const mapStateToProps = (state) => {
    return{
      emision: state.homeReducer.emision,
      pdfFile: state.homeReducer.pdfFile,
      status: state.loginReducer.status
    }
};

const mapDispatchToProps = dispatch => ({
  checkToken: () => {
    dispatch(checkToken());
  },
  downloadPDF: (payload) => {
    dispatch(downloadPDF(payload));
  },
  logout: () => {
    dispatch(logout());
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(SuccessCmp);
