import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router';
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'
import SpinnerWithDimmerCmp from "./SpinnerWithDimmerCmp"
//import myImage from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import * as CONSTANTS from '../constants/constants'
import * as URLMAPPING from '../config/UrlMapping'
import ThemeSelector from "./themes/ThemeSelector"

function LoginCmp(props) {

  const [component, setComponent] = useState({
    username: {name: 'username', type: 'input', value : ''},
    password: {name: 'password', type: 'input', value : ''},

  });
  const [error, setError] = useState(false)
  const [submited, setSubmited] = useState(false)

  /*function onClickLogin(){
    setSubmited(true)
    //console.log("username", component.username.value)
    //console.log("password", component.password.value)

    props.login({
      username: component.username.value, 
      password: component.password.value
    })
  }*/
  const redirectToHome = useCallback(()=>{
    props.history.push(URLMAPPING.HOME)
  },[props.history])

  const onClickLogin = useCallback(()=>{
    setSubmited(true)
    props.login({
      username: component.username.value, 
      password: component.password.value
    })
  },[component.username.value, component.password.value, props])

  useEffect(() => {
    if(props.status === CONSTANTS.LOGGED){
      redirectToHome();
    }
    
    if(submited === true && props.status === CONSTANTS.NOT_LOGGED){
      setError(true)
      setSubmited(false)
    }

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        document.getElementById("login").click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };  
  },[props, submited, redirectToHome, onClickLogin]);

  function handleChange(event) {
    let targetName=event.target.name;
    let targetValue=event.target.value;
    var newComponent = component;
    newComponent[targetName].value = targetValue
    setComponent(newComponent)
  }

  let errorMessage = <div className="ui negative message">
                      <div className="header">
                        Usuario o Contraseña incorrectos por favor vuelva a intentarlo.
                      </div>
                    </div>

  if(component.username === undefined)
    return null;
  
  return (
            <div>
            <ThemeSelector/>
            <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' textAlign='center' className='title-header'>
                  {/*<Image src={myImage} />*/} Iniciar Sesión
                </Header>
                <Form size='large'>
                  <Segment stacked>
                    <Form.Input 
                      fluid icon='user' 
                      iconPosition='left' 
                      placeholder='Usuario' 
                      onChange={(e)=>handleChange(e)} 
                      name={component.username.name} 
                      autoComplete="off"
                      error={error}
                    />
                    <Form.Input 
                      fluid icon='lock' 
                      iconPosition='left' 
                      placeholder='Contraseña' 
                      type='password' 
                      onChange={(e)=>handleChange(e)} 
                      name={component.password.name} 
                      error={error}
                      autoComplete="off"
                    />
                    {error === true ? errorMessage : null}
                    <Button id="login"  fluid size='large' onClick={()=>onClickLogin()} type='button'>Ingresar</Button>
                  </Segment>
                </Form>
                <Message>
                <Link to={URLMAPPING.OLVIDE_MI_CLAVE}>Olvidé mi clave</Link>
                </Message>
              </Grid.Column>
            </Grid>
            <SpinnerWithDimmerCmp active={submited} title={"Espere unos instantes ..."}/>
            </div>
    );
  
}
export default withRouter(LoginCmp)