
import LoginCmp from '../components/LoginCmp.js';

const { connect } = require('react-redux');

const { 
  login
} = require('../actions/loginAction')

const mapStateToProps = (state) => {
    return{
      login: state.loginReducer.result,
      status: state.loginReducer.status
    }
};

const mapDispatchToProps = dispatch => ({
  login: (params) => {
    dispatch(login(params));
  }
 });

export default connect(mapStateToProps, mapDispatchToProps)(LoginCmp);
